import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  onAddingDualLoc,
  onChangeLocsDualInputs,
} from "../../store/Locs/LocsReducer";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LOCMap from "../Map/LOCMap";
import { onFetchingSpecificLocation } from "../../store/Locations/LocationsReducers";

import { notAuthError, url } from "../../constants";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CreateDualLocInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { id } = useParams();
  const { dualLocForm, loading } = useSelector((state) => state.locs);
  const { specificLocation } = useSelector((state) => state.locations);
  const token = useSelector((state) => state.login.token);
  const [radius, setRadius] = useState(10);
  const [originRadius, setOriginRadius] = useState(10);

  // const { selectedIdentifier } = useSelector((state) => state.globalIdentifier);
  // const { selectedEditProject } = useSelector((state) => state.projects);
  // const { selectedEditLocation } = useSelector((state) => state.locations);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [project, setProject] = useState(null);
  const [location, setLocation] = useState(null);
  const [gid, setGid] = useState("");
  const organization = localStorage.getItem("org_id"); 
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  useEffect(() => {
    fetch(`${url}/api/locations/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setLocation(resData.location);
          setGid(resData.globalIdentifier.gid);
        }
      });

    dispatch(onFetchingSpecificLocation(id, token));
  }, [dispatch, id, token, organization]);

  useEffect(() => {
    if (location) {
      fetch(`${url}/api/organizations/categories?organization=${organization}&location=${location.id}&LOC_type=dual`, {
        headers: { Authorization: `Bearer ${token}`, web: true },
      })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setCategories(resData.categories);
        }
      });
    }
  }, [dispatch, id, token, organization, location]);
  return (
    <Fragment>
      <div className="container">
        <ToastContainer />

        {globalIdentifier?.name && project?.name && location?.name ? (
          <Fragment>
          <Link
            to={"/globalidenetifiers"}
            style={styleLinkBack}
          >
            Global Identifiers
          </Link>
          <span className="mx-2" style={{ color: "#28345C" }}>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/viewlocs/" + location.id} style={styleLinkBack}>
              {location.name}
            </Link>
          </Fragment>
        ) : null}

        {/* {selectedEditProject.name && selectedIdentifier.name ? (
          <Fragment>
            <Link to={"/globalidenetifiers"} style={styleLinkBack}>
              {selectedIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/globalidenetifiers/projects/" + selectedEditProject.gid}
              style={styleLinkBack}
            >
              {selectedEditProject.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/viewlocs/" + selectedEditLocation.id}
              style={styleLinkBack}
            >
              {selectedEditLocation.name}
            </Link>
          </Fragment>
        ) : null} */}

        {/* <Link to={"/gid"} style={styleLinkBack}>
          GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          Project 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          Location 1
        </Link> */}
        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="text-center mt-3">Create dual LOC</h3>
            <form
              className="form-horizontal mt-3"
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(
                  onAddingDualLoc(
                    e,
                    token,
                    dualLocForm.unique_asset_id.value,
                    dualLocForm.cableOrigin.value,
                    dualLocForm.filed1Origin.value,
                    dualLocForm.filed2Origin.value,
                    dualLocForm.filed3Origin.value,
                    dualLocForm.origin_latitude.value,
                    dualLocForm.origin_longitude.value,
                    dualLocForm.origin_radius.value,
                    dualLocForm.notes.value,
                    "dual",
                    id,
                    dualLocForm.origin_status.value,
                    dualLocForm.destination_status.value,
                    dualLocForm.cableDestination.value,
                    dualLocForm.filed1Destination.value,
                    dualLocForm.filed2Destination.value,
                    dualLocForm.filed3Destination.value,
                    dualLocForm.lat.value,
                    dualLocForm.long.value,
                    dualLocForm.radius.value,
                    gid,
                    dualLocForm.category.value,
                    dualLocForm.fields.value,
                    dualLocForm.destination_fields.value,
                    navigate
                  )
                );
              }}
            >
              <div className="m-auto col-10 col-md-5">
                  <label 
                    className="form-label"
                    style={{ color: "#0987B1" }}
                  >
                    Category
                  </label>
                  <select 
                    className="form-control"
                    name="Category"
                    id="Category"
                    placeholder="Category"
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        dispatch(onChangeLocsDualInputs(e.target.value, "category"));
                        const category = categories.find((category) => category.id === e.target.value);
                        setSelectedCategory(category);
                        dispatch(onChangeLocsDualInputs("N/A", "filed1Origin"));
                        dispatch(onChangeLocsDualInputs("N/A", "filed2Origin"));
                        dispatch(onChangeLocsDualInputs("N/A", "filed3Origin"));
                        dispatch(onChangeLocsDualInputs("N/A", "filed1Destination"));
                        dispatch(onChangeLocsDualInputs("N/A", "filed2Destination"));
                        dispatch(onChangeLocsDualInputs("N/A", "filed3Destination"));
                        dispatch(onChangeLocsDualInputs(Object.fromEntries(category.fields.map(item => [item, ''])), "fields"));
                        dispatch(onChangeLocsDualInputs(Object.fromEntries(category.destination_fields.map(item => [item, ''])), "destination_fields"));
                      } else {
                        dispatch(onChangeLocsDualInputs({}, "fields"));
                        dispatch(onChangeLocsDualInputs({}, "destination_fields"));
                        dispatch(onChangeLocsDualInputs("", "filed1Origin"));
                        dispatch(onChangeLocsDualInputs("", "filed2Origin"));
                        dispatch(onChangeLocsDualInputs("", "filed3Origin"));
                        dispatch(onChangeLocsDualInputs("", "filed1Destination"));
                        dispatch(onChangeLocsDualInputs("", "filed2Destination"));
                        dispatch(onChangeLocsDualInputs("", "filed3Destination"));
                        dispatch(onChangeLocsDualInputs(null, "category"));
                      }
                    }}
                    value={dualLocForm.category.value}
                    style={
                      dualLocForm.category.valid
                        ? {}
                        : { border: "1px solid red" }
                    }
                  >
                      <option value="">Default</option>
                      {categories.length !== 0 && categories.map((category) => (
                        <option value={category.id}>{category.name}</option>
                      ))}
                  </select>

                  {dualLocForm.category.valid ? (
                    <div style={{ color: "white", fontSize: 14 }}>
                      ""
                    </div>
                  ) : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {dualLocForm.category.validationError}
                    </div>
                  )}
                </div>
              <div className="mb-3 m-auto col-10 col-md-5">
                <label
                  htmlFor="RouteID"
                  className="form-label"
                  style={{ color: "#0987B1" }}
                >
                  Route ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="RouteID"
                  id="RouteID"
                  onChange={(e) =>
                    dispatch(
                      onChangeLocsDualInputs(e.target.value, "unique_asset_id")
                    )
                  }
                  value={dualLocForm.unique_asset_id.value}
                  style={
                    dualLocForm.unique_asset_id.valid
                      ? {}
                      : { border: "1px solid red" }
                  }
                />
                {dualLocForm.unique_asset_id.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                  <div style={{ color: "red", fontSize: 14 }}>
                    {dualLocForm.unique_asset_id.validationError}
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-md-6 m-auto">
                  <div className="mb-3 m-auto col-12">
                    <h5 className="mt-3">LOC Origin</h5>
                    <label
                      htmlFor="CableOrigin"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Cable Origin
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="CableOrigin"
                      id="RouteID"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(e.target.value, "cableOrigin")
                        )
                      }
                      value={dualLocForm.cableOrigin.value}
                      style={
                        dualLocForm.cableOrigin.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.cableOrigin.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.cableOrigin.validationError}
                      </div>
                    )}
                  </div>
                  {/* <div className="mb-3 m-auto col-12">
                    <label
                      htmlFor="Field1"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Field 1
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Field1"
                      id="Field1"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(e.target.value, "filed1Origin")
                        )
                      }
                      value={dualLocForm.filed1Origin.value}
                      style={
                        dualLocForm.filed1Origin.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.filed1Origin.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.filed1Origin.validationError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 m-auto col-12">
                    <label
                      htmlFor="Field2"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Field 2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Field2"
                      id="Field2"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(e.target.value, "filed2Origin")
                        )
                      }
                      value={dualLocForm.filed2Origin.value}
                      style={
                        dualLocForm.filed2Origin.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.filed2Origin.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.filed2Origin.validationError}
                      </div>
                    )}
                  </div> */}
              <div className="px-0 py-2 rounded roudnded-3 col-md-12">
              <label
                  className="form-label"
                  style={{ color: "#0987B1", fontSize: 30 }}
                >
                  Fields
                </label>
                <div className="bg-light rounded rounded-5 py-4 px-5 overflow-y-auto" style={{height: '350px'}}>
                  {dualLocForm.category.value === null ? (
                  <div className="row justify-content-start">
                    <div className="mb-3 col-md-12">
                      <label
                        htmlFor="Field1"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 1
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field1"
                        id="Field1"
                        placeholder="Field 1"
                        onChange={(e) =>
                          dispatch(onChangeLocsDualInputs(e.target.value, "filed1Origin"))
                        }
                        value={dualLocForm.filed1Origin.value}
                        style={
                          dualLocForm.filed1Origin.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {dualLocForm.filed1Origin.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {dualLocForm.filed1Origin.validationError}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-12">
                      <label
                        htmlFor="Field2"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 2
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field2"
                        id="Field2"
                        placeholder="Field 2"
                        onChange={(e) =>
                          dispatch(onChangeLocsDualInputs(e.target.value, "filed2Origin"))
                        }
                        value={dualLocForm.filed2Origin.value}
                        style={
                          dualLocForm.filed2Origin.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {dualLocForm.filed2Origin.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {dualLocForm.filed2Origin.validationError}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-12">
                    <label
                        htmlFor="Field3"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 3
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field3"
                        id="Field3"
                        placeholder="Field 3"
                        onChange={(e) =>
                          dispatch(onChangeLocsDualInputs(e.target.value, "filed3Origin"))
                        }
                        value={dualLocForm.filed3Origin.value}
                        style={
                          dualLocForm.filed3Origin.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {dualLocForm.filed3Origin.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {dualLocForm.filed3Origin.validationError}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row justify-content-start">
                  {selectedCategory?.fields?.map((field) => (
                    <div className="mb-3 col-md-12">
                    <label
                      htmlFor={field.name}
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      {field}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={field}
                      id={field}
                      placeholder={field}
                      onChange={(e) =>
                        dispatch(onChangeLocsDualInputs(e.target.value, field, true))
                      }
                      value={dualLocForm.fields[field]}
                      style={
                        dualLocForm.fields.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.fields.valid ? (
                      <div style={{ color: "white", fontSize: 14 }}>
                        ""
                      </div>
                    ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.fields.validationError}
                      </div>
                    )}
                  </div>
                  ))}
                  </div>
                )}
                </div>
                </div>
                <div className="mb-3 m-auto col-12">
                    <h5 className="text-center my-3">LOC Origin Coordinates</h5>
                    <LOCMap
                      radius={originRadius}
                      locationLat={specificLocation.latitude}
                      locationLong={specificLocation.longitude}
                      onChange={onChangeLocsDualInputs}
                      origin_coordinates={true}
                    />

                    <div className="row my-3">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          name="Latitude"
                          placeholder="Latitude"
                          disabled={true}
                          onChange={(e) => {
                            dispatch(
                              onChangeLocsDualInputs(
                                Number(e.target.value),
                                "origin_latitude"
                              )
                            );
                          }}
                          value={dualLocForm.origin_latitude.value}
                          style={
                            dualLocForm.origin_latitude.valid
                              ? {}
                              : { border: "1px solid red" }
                          }
                        />
                        {true ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                          <div style={{ color: "red", fontSize: 14 }}>
                            {dualLocForm.origin_latitude.validationError}
                          </div>
                        )}
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          name="Longitude"
                          placeholder="Longitude"
                          disabled={true}
                          onChange={(e) =>
                            dispatch(
                              onChangeLocsDualInputs(
                                Number(e.target.value),
                                "origin_longitude"
                              )
                            )
                          }
                          value={dualLocForm.origin_longitude.value}
                          style={
                            dualLocForm.origin_longitude.valid
                              ? {}
                              : { border: "1px solid red" }
                          }
                        />
                        {true ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                          <div style={{ color: "red", fontSize: 14 }}>
                            {dualLocForm.origin_longitude.validationError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="number"
                        step={1}
                        min={0}
                        className="form-control"
                        name="Radius"
                        placeholder="Radius"
                        id="Radius"
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            dispatch(
                              onChangeLocsDualInputs(
                                e.target.value,
                                "origin_radius"
                              )
                            );
                            setOriginRadius(e.target.value);
                          }}
                        }
                        value={
                          dualLocForm.origin_radius.value
                            // ? dualLocForm.origin_radius.value
                            // : 1
                        }
                        // style={
                        //   dualLocForm.radius.valid
                        //     ? {}
                        //     : { border: "1px solid red" }
                        // }
                      />
                      <span style={{ marginLeft: 5 }}>Meter</span>
                    </div>
                  </div>
                  {/* <div className="mb-3 m-auto col-10 col-md-8">
                    <label
                      htmlFor="Field2"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Origin Status
                    </label>
                    <select
                      style={{ width: "100%" }}
                      id="select"
                      className="form-select mt-1 p-2"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(
                            e.target.value,
                            "origin_status"
                          )
                        )
                      }
                      value={dualLocForm.origin_status.value}
                      style={
                        dualLocForm.status.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    >
                      <option value="unassigned">Unassigned</option>
                      <option value="assigned">Assigned</option>
                    </select>
                  </div> */}
                </div>

                <div className="col-12 col-md-6 m-auto">
                  <div className="mb-3 m-auto col-12">
                    <h5 className="mt-3">LOC Destination</h5>
                    <label
                      htmlFor="CableDestination"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Cable Destination
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="CableDestination"
                      id="RouteID"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(
                            e.target.value,
                            "cableDestination"
                          )
                        )
                      }
                      value={dualLocForm.cableDestination.value}
                      style={
                        dualLocForm.cableDestination.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.cableDestination.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.cableDestination.validationError}
                      </div>
                    )}
                  </div>
                  {/* <div className="mb-3 m-auto col-12">
                    <label
                      htmlFor="Field1"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Field 1
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Field1"
                      id="Field1"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(
                            e.target.value,
                            "filed1Destination"
                          )
                        )
                      }
                      value={dualLocForm.filed1Destination.value}
                      style={
                        dualLocForm.filed1Destination.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.filed1Destination.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.filed1Destination.validationError}
                      </div>
                    )}
                  </div>
                  <div className="mb-3 m-auto col-12">
                    <label
                      htmlFor="Field2"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Field 2
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="Field2"
                      id="Field2"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(
                            e.target.value,
                            "filed2Destination"
                          )
                        )
                      }
                      value={dualLocForm.filed2Destination.value}
                      style={
                        dualLocForm.filed2Destination.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.filed2Destination.valid ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.filed2Destination.validationError}
                      </div>
                    )}
                  </div> */}

                  <div className="px-0 py-2 rounded roudnded-3 col-md-12">
              <label
                  className="form-label"
                  style={{ color: "#0987B1", fontSize: 30 }}
                >
                  Fields
                </label>
                <div className="bg-light rounded rounded-5 py-4 px-5 overflow-y-auto" style={{height: '350px'}}>
                  {dualLocForm.category.value === null ? (
                  <div className="row justify-content-start">
                    <div className="mb-3 col-md-12">
                      <label
                        htmlFor="Field1"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 1
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field1"
                        id="Field1"
                        placeholder="Field 1"
                        onChange={(e) =>
                          dispatch(onChangeLocsDualInputs(e.target.value, "filed1Destination"))
                        }
                        value={dualLocForm.filed1Destination.value}
                        style={
                          dualLocForm.filed1Destination.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {dualLocForm.filed1Destination.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {dualLocForm.filed1Destination.validationError}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-12">
                      <label
                        htmlFor="Field2"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 2
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field2"
                        id="Field2"
                        placeholder="Field 2"
                        onChange={(e) =>
                          dispatch(onChangeLocsDualInputs(e.target.value, "filed2Destination"))
                        }
                        value={dualLocForm.filed2Destination.value}
                        style={
                          dualLocForm.filed2Destination.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {dualLocForm.filed2Destination.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {dualLocForm.filed2Destination.validationError}
                        </div>
                      )}
                    </div>
                    <div className="mb-3 col-md-12">
                    <label
                        htmlFor="Field3"
                        className="form-label"
                        style={{ color: "#0987B1" }}
                      >
                        Field 3
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Field3"
                        id="Field3"
                        placeholder="Field 3"
                        onChange={(e) =>
                          dispatch(onChangeLocsDualInputs(e.target.value, "filed3Destination"))
                        }
                        value={dualLocForm.filed3Destination.value}
                        style={
                          dualLocForm.filed3Destination.valid
                            ? {}
                            : { border: "1px solid red" }
                        }
                      />
                      {dualLocForm.filed3Destination.valid ? (
                        <div style={{ color: "white", fontSize: 14 }}>
                          ""
                        </div>
                      ) : (
                        <div style={{ color: "red", fontSize: 14 }}>
                          {dualLocForm.filed3Destination.validationError}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row justify-content-start">
                  {selectedCategory?.destination_fields?.map((field) => (
                    <div className="mb-3 col-md-12">
                    <label
                      htmlFor={field.name}
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      {field}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={field}
                      id={field}
                      placeholder={field}
                      onChange={(e) =>
                        dispatch(onChangeLocsDualInputs(e.target.value, field, true, false))
                      }
                      value={dualLocForm.destination_fields[field]}
                      style={
                        dualLocForm.destination_fields.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    />
                    {dualLocForm.destination_fields.valid ? (
                      <div style={{ color: "white", fontSize: 14 }}>
                        ""
                      </div>
                    ) : (
                      <div style={{ color: "red", fontSize: 14 }}>
                        {dualLocForm.destination_fields.validationError}
                      </div>
                    )}
                  </div>
                  ))}
                  </div>
                )}
                </div>
                </div>
                <div className="mb-3 m-auto col-12">
                    <h5 className="text-center my-3">
                      LOC Destination Coordinates
                    </h5>
                    <LOCMap
                      radius={radius}
                      locationLat={specificLocation.latitude}
                      locationLong={specificLocation.longitude}
                      onChange={onChangeLocsDualInputs}
                    />

                    <div className="row my-3">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          name="Latitude"
                          placeholder="Latitude"
                          disabled={true}
                          onChange={(e) => {
                            dispatch(
                              onChangeLocsDualInputs(
                                Number(e.target.value),
                                "lat"
                              )
                            );
                          }}
                          value={dualLocForm.lat.value}
                          style={
                            dualLocForm.lat.valid
                              ? {}
                              : { border: "1px solid red" }
                          }
                        />
                        {true ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                          <div style={{ color: "red", fontSize: 14 }}>
                            {dualLocForm.lat.validationError}
                          </div>
                        )}
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          name="Longitude"
                          placeholder="Longitude"
                          disabled={true}
                          onChange={(e) =>
                            dispatch(
                              onChangeLocsDualInputs(
                                Number(e.target.value),
                                "long"
                              )
                            )
                          }
                          value={dualLocForm.long.value}
                          style={
                            dualLocForm.long.valid
                              ? {}
                              : { border: "1px solid red" }
                          }
                        />
                        {true ? (
                  <div style={{ color: "white", fontSize: 14 }}>
                    ""
                  </div>
                ) : (
                          <div style={{ color: "red", fontSize: 14 }}>
                            {dualLocForm.long.validationError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "90%",
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="number"
                        step={1}
                        min={0}
                        className="form-control"
                        name="Radius"
                        placeholder="Radius"
                        id="Radius"
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            dispatch(
                              onChangeLocsDualInputs(e.target.value, "radius")
                            );
                            setRadius(e.target.value);
                          }}}
                        value={
                          dualLocForm.radius.value
                            // ? dualLocForm.radius.value
                            // : 1
                        }
                        // style={
                        //   dualLocForm.radius.valid
                        //     ? {}
                        //     : { border: "1px solid red" }
                        // }
                      />
                      <span style={{ marginLeft: 5 }}>Meter</span>
                    </div>
                    {/* {dualLocForm.radius.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {dualLocForm.radius.validationError}
                    </div>
                  )} */}

                    {/* {dualLocForm.status.valid ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {dualLocForm.status.validationError}
                    </div>
                  )} */}
                  </div>

                  {/* <div className="mb-3 m-auto col-10 col-md-8">
                    <label
                      htmlFor="Field2"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Destination Status
                    </label>
                    <select
                      style={{ width: "100%" }}
                      id="select"
                      className="form-select mt-1 p-2"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(
                            e.target.value,
                            "destination_status"
                          )
                        )
                      }
                      value={dualLocForm.destination_status.value}
                      style={
                        dualLocForm.status.valid
                          ? {}
                          : { border: "1px solid red" }
                      }
                    >
                      <option value="unassigned">Unassigned</option>
                      <option value="assigned">Assigned</option>
                    </select>
                  </div> */}
                </div>
              </div>

              <div className="row">
                <div className="col-10 col-md-8 col-lg-7 m-auto">
                  <div className="mb-3 m-auto col-8 col-md-8">
                    <label
                      htmlFor="notes"
                      className="form-label"
                      style={{ color: "#0987B1" }}
                    >
                      Notes
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="notes"
                      id="notes"
                      placeholder="Notes"
                      onChange={(e) =>
                        dispatch(
                          onChangeLocsDualInputs(e.target.value, "notes")
                        )
                      }
                      value={dualLocForm.notes.value}
                    />
                  </div>

                  <div className="d-flex justify-content-center my-4">
                    <button
                      disabled={
                        disableBtn
                          ? disableBtn
                          : !(
                              (
                                dualLocForm.unique_asset_id.valid &&
                                dualLocForm.cableOrigin.valid &&
                                dualLocForm.filed1Origin.valid &&
                                dualLocForm.filed2Origin.valid &&
                                dualLocForm.filed3Origin.valid &&
                                dualLocForm.cableDestination.valid &&
                                dualLocForm.filed1Destination.valid &&
                                dualLocForm.filed2Destination.valid &&
                                dualLocForm.filed3Destination.valid &&
                                true &&
                                dualLocForm.long.valid
                              )
                              // && dualLocForm.radius.valid
                              // dualLocForm.status.valid
                            )
                      }
                      type="submit"
                      className="btn btn-primary w-25"
                      style={{ width: "130px", height: "40px" }}
                      onClick={() => {
                        setTimeout(() => {
                          setDisableBtn(true);
                        }, 1);
                        setTimeout(() => {
                          setDisableBtn(false);
                        }, 2000);
                      }}
                    >
                      {loading ? (
                        <PropagateLoader
                          color={"#fff"}
                          css={override}
                          size={10}
                        />
                      ) : (
                        "Create"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateDualLocInfo;
