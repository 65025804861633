const roles = {
  super_user: "super user",
  user: "user",
  admin: "admin",
  super_admin: "super admin",
  saas_admin: "saas admin",
  // third_party: "third party",
};

export const isHeigherPermission = (userRole, comparedRole) => {
  const rolesValue = {
    // "third party": 0,
    user: 0,
    "super user": 1,
    admin: 2,
    "super admin": 3,
    "saas admin": 4,
  };
  return rolesValue[userRole] > rolesValue[comparedRole];
};

export const notSaasAdminOrSuperAdminOrSuperUser = [
  roles.user,
  roles.admin,
  roles.third_party
]

export const notAdmin = [
  roles.super_user,
  roles.user,
  roles.saas_admin,
  roles.super_admin,
  roles.third_party,
];

export const notUser = [
  roles.super_user,
  roles.admin,
  roles.saas_admin,
  roles.super_admin,
];

export const notSuperUser = [
  roles.user,
  roles.admin,
  roles.saas_admin,
  roles.super_admin,
  roles.third_party,
];

export const notSaas = [
  roles.user,
  roles.admin,
  roles.super_user,
  roles.super_admin,
  roles.third_party,
];

export const notSuperAdmin = [
  roles.user,
  roles.admin,
  roles.super_user,
  roles.saas_admin,
  roles.third_party,
];

export const notSuperAdminOrSuperUser = [
  roles.user,
  roles.admin,
  roles.saas_admin,
  roles.third_party,
];

export const notSuperAdminOrSaasAdmin = [
  roles.user,
  roles.admin,
  roles.super_user,
  roles.third_party,
];

export const notSuperAdminOrSaasAdminOrAdmin = [
  roles.user,
  roles.super_user,
  roles.third_party,
];


export const notAdminOrUser = [
  roles.super_user,
  roles.super_admin,
  roles.saas_admin,
];

export default roles;
