import Modal from "react-modal";
import axios from "axios";
import { Formik } from "formik";
import { url } from "../../constants";
import "./styles.css";
import { ReactComponent as CloseLogo } from "../../assets/images/close-square.svg";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    border: "none",
    padding: "0",
    width: "90%", // Default width for mobile
    maxWidth: "800px", // Max width for larger screens
    backgroundColor: "#fff",
  },
};

export default function SignUpModal({ isOpen, onRequestClose }) {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => onRequestClose(false)}
      contentLabel="Signup Modal"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      ariaHideApp={false}
    >
      <div className="w-full">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 bg-gradient-to-b from-cyan-600 to-blue-900 p-8 flex items-center justify-center rounded-t-lg md:rounded-l-lg md:rounded-tr-none min-h-[200px] md:min-h-[400px]">
            <img
              src="/splash-logo-13.svg"
              className="w-3/4 max-w-xs"
              alt="keltech logo"
              loading="lazy"
            />
          </div>
          <div className="w-full md:w-1/2 p-4 md:p-6">
            <div className="text-right">
              <span
                onClick={() => onRequestClose(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors p-2"
              >
                <CloseLogo />
              </span>
            </div>
            <h5 className="text-center text-xl md:text-2xl font-bold mb-6 text-cyan-500">
              Sign Up
            </h5>
            <Formik
              initialValues={{ organization: "", fullName: "", email: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Email is Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                if (!values.organization) {
                  errors.organization = "Organization name is Required";
                }
                if (!values.fullName) {
                  errors.fullName = "Full Name is Required";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  axios
                  .post(`${url}/api/organizations/registerLite`, {
                    name: values.organization,
                    fullName: values.fullName,
                    email: values.email
                  })
                  .then((res) => {
                    onRequestClose(false);
                    toast.success("Registration successful! Check your email for details");
                  })
                  .catch((err) => {
                    toast.error(err.response.data.error);
                  });
                } catch (e) {
                  toast.error(e.response.data.error);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="my-4">
                  <div className="space-y-4">
                    <div className="form-group">
                      <label
                        htmlFor="organization"
                        className="block font-semibold text-cyan-500 mb-1"
                      >
                        Organization
                      </label>
                      <input
                        type="text"
                        name="organization"
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-cyan-500"
                        placeholder="Enter your organization's name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.organization}
                      />
                      <span className="text-sm text-danger">
                        {errors.organization &&
                          touched.organization &&
                          errors.organization}
                      </span>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="fullName"
                        className="block font-semibold text-cyan-500 mb-1"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-cyan-500"
                        placeholder="Enter your full name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                      />
                      <span className="text-sm text-danger">
                        {errors.fullName && touched.fullName && errors.fullName}
                      </span>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="email"
                        className="block font-semibold text-cyan-500 mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-cyan-500"
                        placeholder="Enter your email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <span className="text-sm text-danger">
                        {errors.email && touched.email && errors.email}
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="mx-auto d-block cta-button popular w-full md:w-auto md:min-w-[200px] px-6 py-3 bg-cyan-500 text-white rounded-lg font-semibold hover:bg-cyan-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed mx-auto block mt-5"
                  >
                    Register
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
}
