import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";
import NavBar from "../../../components/navbar/NavBar";
import ProductCard from "../../../components/buyTags/ProductCard";

import { url, notAuthError } from "../../../constants";
import { useCartActions } from "../../../hooks/useCartActions";

const ProductPage = () => {

  const navigator = useNavigate();

  const { id } = useParams();
  const token = localStorage.getItem("token");

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${url}/api/stripe/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(data.error);
          }}
        else {
          setSelectedProduct(data.Products.find((product) => product.name === id));
        }
      }).then(() => {
        setLoading(false);
      });
  }, [id, token]);
  const { cart, updateQuantity } = useCartActions();

  const handleBack = () => {
    navigator("/buy-tags");
  };

  if (loading) {
    return (                 
    <PropagateLoader
      color={"#0089B8"}
      css={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      size={50}
    />
    )
  }
  
  return (
    <Fragment>
      <NavBar />
      <ProductCard 
        product={selectedProduct} 
        onBack={handleBack} 
        cart={cart} 
        updateQuantity={updateQuantity} />
    </Fragment>
  );
};
export default ProductPage;