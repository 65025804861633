import { ShoppingCart, ArrowRight } from "lucide-react"
import { useNavigate } from "react-router-dom"

const ProceedToCheckout = () => {
    const navigator = useNavigate();
    return (
        <div>
            <button
                onClick={() => navigator('/cart')}
                className="w-full border-0 flex items-center justify-center px-4 py-2 rounded-md text-nowrap bg-gradient-to-r from-cyan-600 to-blue-900 text-white hover:shadow-lg transition-all duration-200"
            >
                <ShoppingCart className="h-4 w-4 mr-2" />
                <span>Proceed to Checkout</span>
                <ArrowRight className="h-4 w-4 ml-2" />
            </button>
        </div>
    )
};

export default ProceedToCheckout;