import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import BuyTags from "../../components/buyTags/BuyTags";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const BuyTagsPage = () => {
  const { org_id } = useSelector((state) => state.login);
  const { id } = useParams();

  const organizationId = org_id || id;

  return (
    <Fragment>
      <NavBar />
      <BuyTags organizationId={organizationId} />
    </Fragment>
  );
};
export default BuyTagsPage;
