import { React, useState } from "react";
import { Check } from "lucide-react";
import "./styles.css";
import SignUpModal from "./SignUpModal";

const PricingCard = ({ plan }) => (
  <div className={`pricing-card ${plan.popular ? "popular" : ""}`}>
    {plan.popular && <div className="popular-badge">Most Popular</div>}
    <div className="card-header">
      <h3 className="card-title">{plan.title}</h3>
      <div className="price">{plan.price}</div>
      <p className="description">{plan.description}</p>
    </div>
    <div className="card-content">
      <ul className="features-list">
        {plan.features.map((feature) => (
          <li key={feature}>
            <Check className="check-icon" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button
        className={`cta-button ${plan.popular ? "popular" : ""}`}
        onClick={plan.onClick}
      >
        {plan.cta}
      </button>
    </div>
  </div>
);

export default function PricingPlans() {
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const plans = [
    {
      title: "LOC Lite",
      price: "Free",
      description: "For individuals or small teams just getting started",
      features: [
        "1 Global Identifier",
        "1 Project",
        "1 Location",
        "1 User Role",
        "Basic Map Features",
        "Photos only",
      ],
      cta: "Get Started",
      onClick: () => setOpenSignUpModal(true),
      popular: false,
    },
    {
      title: "Full LOC Platform",
      price: "Custom Pricing",
      description: "For businesses with advanced asset identification needs",
      features: [
        "Unlimited Global Identifiers",
        "Unlimited Projects",
        "Unlimited Locations",
        "All Available User Roles",
        "Third-Party Access",
        "Offline Capabilities",
        "Photos & Audio Attachments",
        "Advanced Map Features",
      ],
      cta: "Contact Sales",
      onClick: () => (window.location.href = "mailto:loc@keltechioe.com"),
      popular: true,
    },
  ];

  return (
    <div className="pricing-container">
      <div className="pricing-content">
        <div className="pricing-header">
          <h2>Choose Your Plan</h2>
          <p>Select the perfect plan for your asset identification needs</p>
        </div>
        <div className="pricing-grid">
          {plans.map((plan) => (
            <PricingCard key={plan.title} plan={plan} />
          ))}
        </div>
      </div>
      <SignUpModal
        isOpen={openSignUpModal}
        onRequestClose={setOpenSignUpModal}
      />
    </div>
  );
}
