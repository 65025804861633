// ProductDetails.jsx
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { 
  MinusIcon, 
  PlusIcon, 
  ArrowLeft, 
  ZoomIn, 
  ZoomOut, 
  ChevronLeft, 
  ChevronRight,
  Trash2
} from 'lucide-react';
import { useCartActions } from "../../../hooks/useCartActions";
import { useNavigate } from 'react-router-dom';

import './index.css';
import ProceedToCheckout from '../ProceedToCheckout';

const ProductDetails = ({ product: initialProduct, onBack, cart, updateQuantity }) => {
  const [product] = useState(initialProduct);
  const [isZoomed, setIsZoomed] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const thumbnailsRef = useRef(null);
  const navigator = useNavigate();

  const { addToCart, maxQuantity, getTotalCartQuantity, removeFromCart } = useCartActions();

  const count = getTotalCartQuantity();
  let itemInCart = cart.find((item) => item.id === product.id);

  const handleAddToCart = (product, quantity) => {
    addToCart({ ...product, quantity });
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const productImages = product.images;

  const handleImageNavigation = (direction) => {
    if (direction === 'prev') {
      setCurrentImageIndex((prevIndex) => 
        prevIndex > 0 ? prevIndex - 1 : productImages.length - 1
      );
    } else {
      setCurrentImageIndex((prevIndex) => 
        prevIndex < productImages.length - 1 ? prevIndex + 1 : 0
      );
    }
  };

  return (
    <div className="product-details-container">
      <button 
        className="back-button font-bold"
        onClick={onBack}
      >
        <ArrowLeft className="back-icon" />
        Back to Products
      </button>

      <div className="product-layout">
        <div className="image-section">
          <div className="main-image-container">
            <img
              src={productImages[currentImageIndex] || "/placeholder.svg"}
              alt={product.name}
              className={`main-image ${isZoomed ? 'zoomed' : ''}`}
            />
            <button
              onClick={toggleZoom}
              className="zoom-button"
              aria-label={isZoomed ? "Zoom out" : "Zoom in"}
            >
              {isZoomed ? 
                <ZoomOut className="control-icon" /> : 
                <ZoomIn className="control-icon" />
              }
            </button>
            <button
              onClick={() => handleImageNavigation('prev')}
              className="nav-button nav-button-left"
              aria-label="Previous image"
            >
              <ChevronLeft className="control-icon" />
            </button>
            <button
              onClick={() => handleImageNavigation('next')}
              className="nav-button nav-button-right"
              aria-label="Next image"
            >
              <ChevronRight className="control-icon" />
            </button>
          </div>

          <div className="thumbnails-container">
            <div 
              ref={thumbnailsRef}
              className="thumbnails-scroll"
            >
              {productImages.map((image, index) => (
                <button
                  key={index}
                  className={`thumbnail-button ${
                    index === currentImageIndex ? 'active' : ''
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                >
                  <img
                    src={image || "/placeholder.svg"}
                    alt={`${product.name} - LOC ${index + 1}`}
                    className="thumbnail-image"
                  />
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="product-info">
          <h1 className="product-title">{product.name}</h1>
          <p className="product-description">{product.description}</p>
          
          <div className="price-container">
            <span className="price">${product.price}</span>
            <span className="currency">USD</span>
          </div>

          <p className="quantity-limit">
            Maximum limit: {maxQuantity - count} items
          </p>

          <div className="mb-3 flex">
            {cart.find((item) => item.id === product.id) ? (
              <>
              <div className="quantity-controls">
                <button
                  className="quantity-button"
                  onClick={() => updateQuantity(itemInCart.id, itemInCart.quantity - 1)}
                  disabled={itemInCart.quantity <= 1}
                >
                  <MinusIcon className="quantity-icon" />
                </button>
                <span className="quantity-display">{itemInCart.quantity}</span>
                <button
                  className="quantity-button"
                  onClick={() => updateQuantity(itemInCart.id, itemInCart.quantity + 1)}
                  disabled={count >= maxQuantity}
                >
                  <PlusIcon className="quantity-icon" />
                </button>
              </div>

                <button 
                    onClick={() => removeFromCart(itemInCart.id)}
                    className="remove-button border border-2 border-[#9ca3af] ms-2 rounded-3"
                  >
                    <Trash2 className="remove-icon" />
                  </button>
              </>
            ) : (
              <div className="w-full flex flex-column gap-2">
                <button
                  className={`${count >= maxQuantity ? 'add-to-cart-button-disabled' : 'add-to-cart-button'}`}
                  disabled={count >= maxQuantity}
                  onClick={() => {
                    handleAddToCart(product, 1);
                    toast(() => <ProceedToCheckout />)
                  }}
                >
                  Add to Cart
                </button>

                <button
                  className={`${count >= maxQuantity ? 'add-to-cart-button-disabled' : 'add-to-cart-button'}`}
                  disabled={count >= maxQuantity}
                  onClick={() => {
                    handleAddToCart(product, 1);
                    navigator('/cart');
                  }}
                >
                  Buy Now
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;