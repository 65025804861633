import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./assets/css/bootstrap.min.css";
import "./App.css";
import { toast } from "react-toastify";

import UsersPage from "./pages/users/UsersPage";
import LoginPage from "./pages/login/LoginPage";
import SignupPage from "./pages/signup/SignupPage";
import HomePage from "./pages/home/HomePage";
import GidPage from "./pages/gid/GidPage";
import UserLogsPage from "./pages/users/UsersLogsPage";
import GidLogsPage from "./pages/gid/GidLogsPage";
import ProjectLogsPage from "./pages/project/ProjectLogsPage";
import LocationLogsPage from "./pages/project/LocationLogsPage";

import GlobalIdenetifiersPage from "./pages/global idenetifiers/GlobalIdenetifiersPage";
import GlobalSearchPage from "./pages/global idenetifiers/GlobalSearchPage";
import AddNewGlobalIdenetifiersPage from "./pages/global idenetifiers/AddNewGlobalIdenetifiersPage";
import EditGlobalIdenetifiersPage from "./pages/global idenetifiers/EditGlobalIdenetifiersPage";
import ProjectPage from "./pages/project/ProjectPage";
import AddNewProjectPage from "./pages/project/AddNewProjectPage";
import EditProjectPage from "./pages/project/EditProjectPage";
import AddNewLocationPage from "./pages/project/AddNewLocationPage";
import EditLocationPage from "./pages/project/EditLocationPage";
import ProjectsPage from "./pages/project/ProjectsPage";
import ProjectConsumptionReportPage from "./pages/project/ProjectConsumptionReportPage";
import LocationConsumptionReportPage from "./pages/project/LocationConsumptionReportPage";
import ViewLocsPage from "./pages/locs/ViewLocsPage";
import AssignedLocsPage from "./pages/locs/AssignedLocsPage";
import UnAssignedLocsPage from "./pages/locs/UnAssignedLocsPage";
import CreateDualLocInfoPage from "./pages/locs/CreateDualLocInfoPage";
import CreateSingleLocInfoPage from "./pages/locs/CreateSingleLocInfoPage";
import UpdateDualLocInfoPage from "./pages/locs/UpdateDualLocInfoPage";
import UpdateSingleLocInfoPage from "./pages/locs/UpdateSingleLocInfoPage";
import NotFound404Page from "./pages/NotFound404/NotFound404Page";
import ForgetPasswordPage from "./pages/forgetPassword/ForgetPasswordPage";
import MailForResetPasswordPage from "./pages/forgetPassword/MailForResetPasswordPage";
import UpgradeTags from "./pages/upgradeTags";
import ConsumptionReportPage from "./pages/consumptionReport/ConsumptionReport";

import { authCheckState } from "./store/Login/Login";

import Organizations from "./pages/organizations/Organizations";
import RedeemCodes from "./pages/redeem-codes/redeem-codes";
import BuyTags from "./pages/buyTags/buyTags";
import ProductPage from "./pages/buyTags/ProductPage/ProductPage";
import CartPage from "./pages/buyTags/Cart/Cart";
import Organization from "./pages/organizations/Organization";
// import useAutoLogout from "./hooks/useAutoLogout";
import { useLogout } from "./contexts/LogoutContext";
import ProjectDetailedMapPage from "./pages/project/ProjectDetailedMapPage";
import LocationDetailedMapPage from "./pages/project/LocationDetailedMapPage";
import ThirdParties from "./pages/thirdParty/ThirdParties";
import Hero from "./pages/hero/Hero";
import OTPSigninPage from "./pages/OTPSignin/OTPSigninPage";
import RecycleBinPage from "./pages/project/RecycleBinPage";
import SuccessfulLitePayment from "./pages/hero/SuccessfulLitePayment";
import { CartProvider } from './contexts/cart/cartContext';

const FullVersionWrapper = ({ children }) => {
  const lite = useSelector((state) => state.login.lite);

  if (lite) {
    return <UsersPage />;
  }

  return children;
};

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const { setLogout } = useLogout();

  // its working dont remove the timer.
  // const timer = useAutoLogout(60 * 15);

  useEffect(() => {
    document.title = "Label on Cable";
  }, []);

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch, token]);

  useEffect(() => {
    toast.onChange((payload) => {
      if (
        payload.content === "you session expired, please login again." ||
        payload.content === "Unable to authenticate!"
      ) {
        setLogout(true);
      }
    });
  }, [setLogout]);

  if (!token) {
    return (
      <Routes>
        <Route path="*" element={<Hero />} />
        <Route path="/" element={<Hero />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetpassword" element={<ForgetPasswordPage />} />
        <Route path="/forgetpassword" element={<MailForResetPasswordPage />} />
        <Route path="/2fa" element={<OTPSigninPage />} />
      </Routes>
    );
  }

  return (
    <CartProvider>
      <div>
        <Routes>
          <Route path="*" element={<NotFound404Page />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/userslogs/:id" element={<UserLogsPage />} />

          <Route
            path="/successfulLitePayment"
            element={<SuccessfulLitePayment />}
          />

          <Route
            path="/gid"
            element={
              <FullVersionWrapper>
                <GidPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/gidlogs/:id"
            element={
              <FullVersionWrapper>
                <GidLogsPage />
              </FullVersionWrapper>
            }
          />

          <Route
            path="/globalsearch"
            element={
              <FullVersionWrapper>
                <GlobalSearchPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/globalidenetifiers"
            element={
              <FullVersionWrapper>
                <GlobalIdenetifiersPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/addglobalidenetifiers"
            element={
              <FullVersionWrapper>
                <AddNewGlobalIdenetifiersPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/editglobalidenetifiers/:id"
            element={
              <FullVersionWrapper>
                <EditGlobalIdenetifiersPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/globalidenetifiers/projects/:id"
            element={
              <FullVersionWrapper>
                <ProjectsPage />
              </FullVersionWrapper>
            }
          />

          <Route
            path="/projectlogs/:id"
            element={
              <FullVersionWrapper>
                <ProjectLogsPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/locationlogs/:id"
            element={
              <FullVersionWrapper>
                <LocationLogsPage />
              </FullVersionWrapper>
            }
          />

          <Route
            path="/locations/:id"
            element={
              <FullVersionWrapper>
                <ProjectPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/locations/:id/detailed-map"
            element={
              <FullVersionWrapper>
                <ProjectDetailedMapPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/locations/:id/consumption-report"
            element={
              <FullVersionWrapper>
                <ProjectConsumptionReportPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/addnewproject/:id"
            element={
              <FullVersionWrapper>
                <AddNewProjectPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/editproject/:id"
            element={
              <FullVersionWrapper>
                <EditProjectPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/addnewlocation/:id"
            element={
              <FullVersionWrapper>
                <AddNewLocationPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/editlocation/:id"
            element={
              <FullVersionWrapper>
                <EditLocationPage />
              </FullVersionWrapper>
            }
          />

          <Route
            path="/viewlocs/:id/consumption-report"
            element={
              <FullVersionWrapper>
                <LocationConsumptionReportPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/viewlocs/:id"
            element={
              <FullVersionWrapper>
                <ViewLocsPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/viewlocs/:id/detailed-map"
            element={
              <FullVersionWrapper>
                <LocationDetailedMapPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/viewlocs/:id/recycle-bin"
            element={
              <FullVersionWrapper>
                <RecycleBinPage />
              </FullVersionWrapper>
            }
          />

          <Route
            path="/assignedlocs/:id"
            element={
              <FullVersionWrapper>
                <AssignedLocsPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/unassignedlocs/:id"
            element={
              <FullVersionWrapper>
                <UnAssignedLocsPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/CreateDualLocInfo/:id"
            element={
              <FullVersionWrapper>
                <CreateDualLocInfoPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/CreateSingleLocInfo/:id"
            element={
              <FullVersionWrapper>
                <CreateSingleLocInfoPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/UpdateDualLocInfo/:id"
            element={
              <FullVersionWrapper>
                <UpdateDualLocInfoPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/UpdateSingleLocInfo/:id"
            element={
              <FullVersionWrapper>
                <UpdateSingleLocInfoPage />
              </FullVersionWrapper>
            }
          />
          <Route path="/UpgradeTags/:id" element={<UpgradeTags />} />

          <Route
            path="/organizations"
            element={
              <FullVersionWrapper>
                <Organizations />
              </FullVersionWrapper>
            }
          />

          <Route
            path="/redeem-codes"
            element={
              <FullVersionWrapper>
                <RedeemCodes />
              </FullVersionWrapper>
            }
          />

          <Route path="/buy-tags" element={<BuyTags />} />
          <Route path="/buy-tags/:id" element={<ProductPage />} />
          <Route path={"/cart"} element={<CartPage />} />

          <Route
            path="/organization"
            element={
              <FullVersionWrapper>
                <Organization />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/consumption-report"
            element={
              <FullVersionWrapper>
                <ConsumptionReportPage />
              </FullVersionWrapper>
            }
          />
          <Route
            path="/consumption-report/:id"
            element={
              <FullVersionWrapper>
                <ConsumptionReportPage />
              </FullVersionWrapper>
            }
          />

          <Route
            path="/third-party"
            element={
              <FullVersionWrapper>
                <ThirdParties />
              </FullVersionWrapper>
            }
          />
        </Routes>
      </div>
    </CartProvider>
  );
}

export default App;
