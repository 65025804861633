import { useSelector } from 'react-redux';
import {capitalize} from '../../util/capitalize';
import roles from '../../util/roles';
const FormikInput = ({formik,name,placeholder,type='text'}) => {
    const role = useSelector((state) => state.login.role);
  
    return ( 
        <div>
        <input
        type={type}
        id={name}
        className={
          formik.errors[name] && formik.touched[name]
            ? `form-control mt-3 p-2 bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:border-red-500`
            : `form-control mt-3 p-2`
        }
        placeholder={placeholder?placeholder : capitalize(name)}
        name={name}
        onChange={formik.handleChange}
        value={formik.values[name]}
        readOnly={role === roles.super_user}
      />
      <p
        className={
          formik.errors[name] &&
          formik.touched[name] &&
          `text-sm text-red-500`
        }
      >
        {formik.errors[name] && formik.touched[name] ? (
          <div className="my-1 text-sm text-red-700">
            {formik.errors[name]}
          </div>
        ) : null}
      </p>
    </div>
     );
}
 
export default FormikInput;