// CartPage.jsx
import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { 
  Trash2, 
  Plus, 
  Minus, 
  ArrowLeft, 
  ShoppingBag 
} from 'lucide-react';

import {url} from '../../../constants';

import './index.css';

const CartPage = ({ 
  cart, 
  onClose, 
  onRemoveItem, 
  onUpdateQuantity, 
  getCartTotal,
  getTotalCartQuantity,
  maxQuantity
}) => {
  const totalPrice = getCartTotal();
  const totalQuantity = getTotalCartQuantity();
  const token = useSelector((state) => state.login.token);
  const [disabled, setDisabled] = useState(true);

  const onChange = (value) => {
    setDisabled(!disabled);
  };

  const handleCheckout = async () => {
    const quantity = cart.reduce((acc, item) => acc + item.quantity, 0);
    const response = await axios.post(
      `${url}/api/stripe/buy-tags`,
      { quantity, items: cart },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          web: true,
        },
      }
    );
    window.location.href = response.data.session.url;
  };

  return (
    <div className="cart-page">
      <div className="page-header">
        <button onClick={onClose} className="back-button font-bold">
          <ArrowLeft className="back-icon" />
          Back to Products
        </button>
        <h1 className="page-title">Your Cart</h1>
      </div>

      <div className="cart-layout">
        <div className="cart-items-section">
          {cart.length === 0 ? (
            <div className="empty-cart-card">
              <div className="empty-cart-content">
                <ShoppingBag className="empty-cart-icon" />
                <p className="empty-cart-message">Your cart is empty</p>
                <button 
                  onClick={onClose} 
                  className="continue-shopping-button"
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          ) : (
            <div className="cart-items-list">
              {cart.map((item) => (
                <div key={item.id} className="cart-item">
                  <div className="item-image-container">
                    <img 
                      src={item.image || "/placeholder.svg"} 
                      alt={item.name} 
                      className="item-image"
                    />
                  </div>
                  <div className="item-details">
                    <div className="item-header">
                      <h3 className="item-name">{item.name}</h3>
                      <span className="item-tag">{item.quantity * 50} NFC Tags</span>
                    </div>
                    <p className="item-price">
                      ${(item.price * item.quantity).toFixed(2)}
                    </p>
                    <div className="quantity-controls">
                      <button
                        onClick={() => onUpdateQuantity(item.id, item.quantity - 1)}
                        disabled={item.quantity <= 1}
                        className="quantity-button"
                      >
                        <Minus className="quantity-icon" />
                      </button>
                      <span className="quantity-display">{item.quantity}</span>
                      <button
                        onClick={() => onUpdateQuantity(item.id, item.quantity + 1)}
                        disabled={totalQuantity >= maxQuantity}
                        className="quantity-button"
                      >
                        <Plus className="quantity-icon" />
                      </button>
                    </div>
                  </div>
                  <button 
                    onClick={() => onRemoveItem(item.id)}
                    className="remove-button"
                  >
                    <Trash2 className="remove-icon" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="summary-section">
          <div className="summary-card">
            <h2 className="summary-title">Order Summary</h2>
            <div className="summary-details">
              <div className="summary-row">
                <span className="summary-label">Subtotal</span>
                <span className="summary-value">${totalPrice.toFixed(2)}</span>
              </div>
              <div className="summary-row">
                <span className="summary-label">Shipping</span>
                <span className="summary-value">$0.00</span>
              </div>
              <div className="summary-row">
                <span className="summary-label">Tax</span>
                <span className="summary-value">$0.00</span>
              </div>
            </div>
            <div className='w-full flex justify-center py-4'>
              <ReCAPTCHA
                sitekey={"6LfpyqgqAAAAALPrHLUQdHXIIr6J7I43XWdXdnH6"}
                onChange={onChange}
              />
            </div>
            <div className="summary-total">
              <div className="total-row">
                <span className="total-label">Total</span>
                <span className="total-value">${totalPrice.toFixed(2)}</span>
              </div>
              <button
                onClick={handleCheckout}
                className="checkout-button"
                // ! until the production stripe api key is provided, this button will be disabled
                // disabled={cart.length === 0 || disabled}
                disabled={true}
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;