import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
// import Confetti from "react-confetti";
import { toast } from "react-toastify";
import goolePlay from "../../assets/images/gooleplay.png";
import appStore from "../../assets/images/appstore.png";
import { useCartActions } from "../../hooks/useCartActions";
import "./successfulLitePayment.css";
import "./styles.css";

import { url } from "../../constants";

const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export default function SuccessfulLitePayment() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [paymentData, setPaymentData] = useState(null);
  const { clearCart } = useCartActions();

  useEffect(() => {
    axios
      .post(
        `${url}/api/stripe/confirm-payment`,
        {
          session_id: params.get("session_id"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            web: true,
          },
        }
      )
      .then((res) => {
        toast.success("Payment successful! Check your email for details");
        setPaymentData(res.data.payment_data);
      })
      .then(() => {
        localStorage.removeItem("shopping_cart");
        clearCart();
      })
      .catch((err) => {
        toast.error("Payment failed. " + err.response.data.error);
      });
  }, [params, token]);

  // const [showConfetti, setShowConfetti] = useState(true);
  const [windowDimension, setWindowDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimension({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="payment-container">
      {/* {showConfetti && (
        <Confetti
          width={windowDimension.width}
          height={windowDimension.height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.05}
          colors={["#22d3ee", "#0ea5e9", "#2563eb", "#4ade80", "#a78bfa"]}
          onConfettiComplete={() => setShowConfetti(false)}
        />
      )} */}

      <div className="card glow-card">
        <div className="card-header">
          <div className="icon-container">
            <CheckIcon />
          </div>
          <h2 className="card-title">Payment Successful!</h2>
          <p className="card-subtitle">Your transaction has been processed</p>
        </div>

        <div className="card-content">
          <div className="transaction-details">
            <div className="detail-row">
              <span>Amount Paid</span>
              <span className="detail-value">
                ${paymentData?.amount / 100 || ""}
              </span>
            </div>
            <div className="detail-row">
              <span>Full Name</span>
              <span className="detail-value">
                {paymentData?.fullName || ""}
              </span>
            </div>
            <div className="detail-row">
              <span>Email</span>
              <span className="detail-value">{paymentData?.email || ""}</span>
            </div>
            <div className="detail-row">
              <span>Organization</span>
              <span className="detail-value">
                {paymentData?.organization || ""}
              </span>
            </div>
          </div>
          <div>
            <button
              className="cta-button popular"
              onClick={() => {
                localStorage.removeItem("pendingRegistration");
                navigate("/users");
              }}
            >
              Go Back Home
            </button>
            <div className="d-flex align-items-center justify-content-between mt-4">
              <a
                href="https://apps.apple.com/eg/app/loc-lable-on-a-cable/id1643531827"
                rel="noreferrer"
                target="_blank"
                className="me-3"
              >
                <img src={appStore} alt="appstore" className="img-fluid" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.keltech.loc"
                rel="noreferrer"
                target="_blank"
              >
                <img src={goolePlay} alt="gooleplay" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
