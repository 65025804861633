import React, { Fragment } from "react";
import NavBar from "../../components/navbar/NavBar";
import RedeemCodes from "../../components/redeem-codes/redeem-codes";
import NotAuthPage from "../../pages/NotAuth/NotAuthPage";

import { notSaas } from "../../util/roles";
import { useSelector } from "react-redux";

const RedeemCodesPage = () => {
  const role = useSelector((state) => state.login.role);
  if (notSaas.includes(role)) return <NotAuthPage />;

  return (
    <Fragment>
      <NavBar />
        <RedeemCodes />
    </Fragment>
  );
};
export default RedeemCodesPage;