import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import { notAuthError, url } from "../constants";

const Logs = ({ name, handleDownload, data, setFlag, error, errorMsg }) => {
  const token = useSelector((state) => state.login.token);

  const handleMarkAsSeen = (time) => {
    fetch(`${url}/api/logs/${time}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        }

        if (resData.error && resData.error[0]) {
          if (resData.error[0].message === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error[0].message);
          }
        }

        if (resData.message) {
          setFlag(false);
        }
      });
  };

  const [renderedData, setRenderedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value.trim() === "") {
      setRenderedData(data);
    } else {
      setRenderedData(
        data.filter(
          (d) =>
            d.user_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            d.description.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    if (data) {
      setRenderedData(data);
    }
  }, [data]);

  const unSeenState = data?.filter((log) => log.state === false);
  const seenState = data?.filter((log) => log.state === true);
  const [renderItem, setRenderItem] = useState("all");

  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        <div className="row">
          {name ? (
            <div className="m-auto">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="text-center my-3">{name} Logs</h3>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "20%",
                  }}
                >
                  {/* <select
                    id="select"
                    className="form-select"
                    onChange={(e) => {
                      setRenderItem(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  >
                    <option value="all">Filter By</option>
                    <option value="notSeen">Not seen</option>
                    <option value="seen">Seen</option>
                  </select> */}
                  <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "600%",
                  }}
                >
                  <button onClick={handleDownload} className="btn btn-primary">
                    Download Logs
                  </button>
                </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <input
                  className="form-control my-3 p-2"
                  placeholder="Search by user or description..."
                  type="text"
                  value={searchValue}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <select
                    id="select"
                    className="form-select py-2 ms-2"
                    onChange={(e) => {
                      setRenderItem(e.target.value);
                    }}
                    style={{ width: "30%" }}
                  >
                    <option value="all">Filter By</option>
                    <option value="notSeen">Not seen</option>
                    <option value="seen">Seen</option>
                  </select>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "20%",
                  }}
                >
                  <button onClick={handleDownload} className="btn btn-primary">
                    Download Logs
                  </button>
                </div> */}
              </div>
              <div
                className="table-responsive"
                style={{ maxHeight: window.innerHeight - 200 }}
              >
                <table className="table rounded-t-lg">
                  <thead className="sticky top-0">
                    <tr className="bg-primary rounded-t-lg" style={{ color: "white" }}>
                      <th scope="col">DESCRIPTION</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col">UPDATED AT</th>
                      <th scope="col">STATE</th>
                    </tr>
                  </thead>
                  {renderedData &&
                  renderedData.length > 0 &&
                  renderItem === "all"
                    ? renderedData.map((item) =>
                        error ? (
                          <div>{errorMsg}</div>
                        ) : (
                          <tbody key={item.time}>
                            <tr scope="row">
                              <td>
                                <div
                                  style={{
                                    width: "300px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {item.description}
                                </div>
                              </td>
                              <td>{item.user_name}</td>
                              <td>{new Date(item.time).toUTCString()}</td>
                              {/* <td>{item.state}</td> */}
                              {/* <td>{item.status_code}</td> */}
                              <td>
                                {item.state ? (
                                  <button className="btn btn-primary" disabled>
                                    Seen
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleMarkAsSeen(item.time);
                                      setFlag(true);
                                    }}
                                  >
                                    Mark As Seen
                                  </button>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        )
                      )
                    : null}

                  {renderedData &&
                  renderedData.length > 0 &&
                  renderItem === "notSeen"
                    ? unSeenState.map((item) => {
                        return (
                          <tbody key={item.time}>
                            <tr scope="row">
                              <td>
                                <div
                                  style={{
                                    width: "300px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {item.description}
                                </div>
                              </td>
                              <td>{item.user_name}</td>
                              <td>{new Date(item.time).toUTCString()}</td>
                              <td>
                                {item.state ? (
                                  <button className="btn btn-primary" disabled>
                                    Seen
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleMarkAsSeen(item.time);
                                      setFlag(true);
                                    }}
                                  >
                                    Mark As Seen
                                  </button>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    : null}

                  {renderedData &&
                  renderedData.length > 0 &&
                  renderItem === "seen"
                    ? seenState.map((item) => {
                        return (
                          <tbody key={item.time}>
                            <tr scope="row">
                              <td>
                                <div
                                  style={{
                                    width: "300px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {item.description}
                                </div>
                              </td>
                              <td>{item.user_name}</td>
                              <td>{new Date(item.time).toUTCString()}</td>
                              <td>
                                {item.state ? (
                                  <button className="btn btn-primary" disabled>
                                    Seen
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      handleMarkAsSeen(item.time);
                                    }}
                                  >
                                    Mark As Seen
                                  </button>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    : null}
                </table>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center", padding: "20px 0" }}>
              <div className="spinner-border" role="status"></div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default Logs;
