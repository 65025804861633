import { ACTIONS } from './cartActions';

export function cartReducer(state, action) {
  switch (action.type) {
    case ACTIONS.ADD_TO_CART: {
      const existingItem = state.find(item => item.id === action.payload.id);
      
      if (existingItem) {
        return state.map(item =>
          item.id === action.payload.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      
      return [...state, { ...action.payload, quantity: 1 }];
    }
    
    case ACTIONS.REMOVE_FROM_CART: {
      return state.filter(item => item.id !== action.payload.id);
    }
    
    case ACTIONS.UPDATE_QUANTITY: {
      const { id, quantity } = action.payload;
      if (quantity < 1) {
        return state.filter(item => item.id !== id);
      }
      
      return state.map(item =>
        item.id === id ? { ...item, quantity } : item
      );
    }
    
    case ACTIONS.CLEAR_CART: {
      return [];
    }

    case ACTIONS.UPDATE_MAX_QUANTITY: {
      return [...state]; // Force re-render without changing state
    }
    
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}