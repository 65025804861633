import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  onDeletingLoc,
  onFetchingLocs,
  onResetingDualLocForm,
  onResetingSingleLocForm,
  onSearchingLoc,
  onSortingLocs,
  onDeletingSelectedLoc,
  toggleLock,
} from "../../store/Locs/LocsReducer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ExcelRenderer } from "react-excel-renderer";

import Modal from "react-modal";
import styles from "./../project/Project.module.css";

import { notAuthError, url } from "../../constants";
import { notAdminOrUser, notUser } from "../../util/roles";
import SingleLocsTable from "./SingleLocsTable";
import DualLocsTable from "./DualLocsTable";
import DynamicSingleLocsTable from "./DynamicSingleLocsTable";
import DynamicDualLocsTable from "./DynamicDualLocsTable";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
};
const UnAssignedLocs = () => {
  const [addFileIsOpen, setAddFileIsOpen] = React.useState(false);
  const [DeleteSingleIsOpen, setDeleteSingleIsOpen] = useState(false);
  const [singleLocID, setSingleLocID] = useState("");
  const [DeleteDualIsOpen, setDeleteDualIsOpen] = useState(false);
  const [DeleteMultiIsOpen, setDeleteMultiIsOpen] = useState(false);
  const [DeleteMultiDualIsOpen, setDeleteMultiDualIsOpen] = useState(false);

  const [dualLocID, setDualLocID] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [gid, setGid] = useState("");
  const [project, setProject] = useState(null);
  const [location, setLocation] = useState(null);
  const [selectedSingleLocs, setSelectedSingleLocs] = useState([]);
  const [selectedDualLocs, setSelectedDualLocs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [LOCSearchType, setLOCSearchType] = useState("unique_asset_id");
  const [type, setType] = useState("single");
  const organization = useSelector((state) => state.login.org_id);
  const [categories, setCategories] = useState([]);

  const [softLockOpen, setSoftLockOpen] = useState(false);
  const [selectedLoc, setSelectedLoc] = useState(null);

  const [selectedTemplate, setSelectedTemplate] = useState("default");

  const onSelectLoc = (id, type) => {
    if (type === "single") {
      if (selectedSingleLocs.includes(id)) {
        setSelectedSingleLocs(selectedSingleLocs.filter((loc) => loc !== id));
      } else {
        setSelectedSingleLocs([...selectedSingleLocs, id]);
      }
    } else {
      if (selectedDualLocs.includes(id)) {
        setSelectedDualLocs(selectedDualLocs.filter((loc) => loc !== id));
      } else {
        setSelectedDualLocs([...selectedDualLocs, id]);
      }
    }
  };

  const onSelectAllSingleLocs = (arr) => {
    if (selectedSingleLocs.toString() === arr.toString()) {
      setSelectedSingleLocs([]);
    } else {
      setSelectedSingleLocs(arr);
    }
  };

  const onSelectAllDualLocs = (arr) => {
    if (selectedDualLocs.toString() === arr.toString()) {
      setSelectedDualLocs([]);
    } else {
      setSelectedDualLocs(arr);
    }
  };

  const handleOpenLockModal = (loc) => {
    setSoftLockOpen(true);
    setSelectedLoc(loc);
  };

  const handleCloseModal = () => {
    setSoftLockOpen(false);
    setSelectedLoc(null);
  };

  const handleToggleSoftLock = () => {
    dispatch(
      toggleLock(
        selectedLoc.loc_id,
        selectedLoc.LOC_type,
        token,
        handleCloseModal
      )
    );
  };

  const handleDownloadTemplate = (e, type) => {
    e.preventDefault();
    fetch(
      `${url}/api/organizations/categories/download-template/${type}${selectedTemplate !== "" ? `?id=${selectedTemplate}` : ""
      }`,
      {
        headers: { Authorization: `Bearer ${token}`, web: true },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `template_${type}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const { token, role } = useSelector((state) => state.login);

  const [flag, setFlag] = useState(false);

  const {
    singleLocs,
    dualLocs,
    loadingLocs,
    renderedItem,
    searchSingleLocs,
    searchDualLocs,
    loadSearch,
  } = useSelector((state) => state.locs);

  useEffect(() => {
    dispatch(onFetchingLocs(id, token, "unassigned"));
    dispatch(onSearchingLoc(singleLocs, dualLocs, ""));

    fetch(`${url}/api/locations/${id}`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setLocation(resData.location);
          setGid(resData.globalIdentifier.gid);
        }
      });
  }, [dispatch, flag]);

  useEffect(() => {
    if (location) {
      fetch(
        `${url}/api/organizations/categories?organization=${organization}&location=${location.id}`,
        {
          headers: { Authorization: `Bearer ${token}`, web: true },
        }
      )
        .then((res) => res.json())
        .then((resData) => {
          if (resData.error) {
            if (resData.error === notAuthError) {
              toast("your session expired, please login again.");
            } else {
              toast.error(resData.error);
            }
          } else {
            setCategories(resData.categories);
          }
        });
    }
  }, [location, organization, token]);

  const [state, setState] = useState();
  const [file, setFile] = useState({});
  const fileHandler = (event) => {
    let fileObj = event.target.files[0];

    setFile(fileObj);
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  const onUploadFile = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("LocFile", file);    
    formData.append("ogr_id", globalIdentifier.org_id)

    fetch(`${url}/api/LOCs/upload/${id}?type=${type}`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, web: true },
      body: formData
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            const error = resData.error;
            if (typeof error === "string") {
              toast.error(resData.error);
            } else if (
              typeof Object.entries(resData.error).map(([k, v]) => v)[0] ===
              "string"
            ) {
              toast.error(Object.entries(resData.error).map(([k, v]) => v)[0]);
            } else {
              toast.error(
                Object.entries(resData.error).map(([k, v]) => v)[0][0]
              );
            }
          }
        } else {
          if (resData.message) {
            toast.success(resData.message);
            setFlag(true);
          }
        }

        setFlag(false);
      });
  };

  const [sortType, setSortType] = useState("");
  const [locSorted, setLocSorted] = useState("");

  const sortTable = (locType, sort) => {
    let type = "desc";
    setLocSorted(locType);
    if (sort === sortType) {
      setSortType("");
      type = "asc";
    } else {
      setSortType(sort);
    }
    onSortingLocs(dualLocs, singleLocs, type, sort, locType);
  };

  return (
    <Fragment>
      <div className="container">
        <ToastContainer />

        {globalIdentifier?.name && project?.name && location?.name ? (
          <Fragment>
            <Link to={"/globalidenetifiers"} style={styleLinkBack}>
              Global Identifiers
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/viewlocs/" + location.id} style={styleLinkBack}>
              {location.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/unassignedlocs/" + id} style={styleLinkBack}>
              Unassigned
            </Link>
          </Fragment>
        ) : null}

        {/* {selectedEditProject.name && selectedIdentifier.name ? (
          <Fragment>
            <Link
              to={"/globalidenetifiers/projects/" + selectedEditProject.gid}
              style={styleLinkBack}
            >
              {selectedIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/locations/" + selectedEditProject.id}
              style={styleLinkBack}
            >
              {selectedEditProject.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link
              to={"/viewlocs/" + selectedEditLocation.id}
              style={styleLinkBack}
            >
              {selectedEditLocation.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/unassignedlocs/" + id} style={styleLinkBack}>
              UNASSIGNED
            </Link>
          </Fragment>
        ) : null} */}

        {/* <Link to={"/gid"} style={styleLinkBack}>
            GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
            Project 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
            Location 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
            UNASSIGNED
        </Link> */}
        {notUser.includes(role) && (
          <>
            <div className="row w-full m-auto mb-3 mt-4">
              <div className="col d-flex">
                <Link
                  className="w-100"
                  to={"/CreateSingleLocInfo/" + id}
                  onClick={() => dispatch(onResetingSingleLocForm())}
                >
                  <button className="btn btn-primary h-100 w-100">
                    Create New Single LOC
                  </button>
                </Link>
              </div>
              <div className="col d-flex">
                <Link
                  className="w-100"
                  to={"/CreateDualLocInfo/" + id}
                  onClick={() => dispatch(onResetingDualLocForm())}
                >
                  <button className="btn btn-primary h-100 w-100">
                    Create New Dual LOC
                  </button>
                </Link>
              </div>
              <div className="col d-flex">
                <button
                  type="button"
                  className="border-0 rounded-md text-white bg-[#65CC57] w-100"
                  // data-bs-toggle="modal"
                  // data-bs-target="#ImportModal"
                  onClick={() => {
                    setAddFileIsOpen(true);
                    setType("single");
                  }}
                >
                  Import Single LOC’s via Excel
                </button>
              </div>
              <div className="col d-flex">
                <button
                  type="button"
                  className="border-0 rounded-md text-white bg-[#65CC57] w-100 py-3"
                  // data-bs-toggle="modal"
                  // data-bs-target="#ImportModal"
                  onClick={() => {
                    setAddFileIsOpen(true);
                    setType("dual");
                  }}
                >
                  Import Dual LOC’s via Excel
                </button>
              </div>
              {/* Model */}
              <Modal
                isOpen={addFileIsOpen}
                style={customStyles}
                onRequestClose={() => setAddFileIsOpen(false)}
                contentLabel="Add Modal"
                ariaHideApp={false}
                className={styles.Modal}
                overlayClassName={styles.Overlay}
              >
                <div className="modal-body">
                  <div className="row">
                    <form
                      onSubmit={(e) => {
                        onUploadFile(e);
                        setAddFileIsOpen(false);
                      }}
                    >
                      <div className="col">
                        <h3 className="text-center mt-5 text-primary">
                          IMPORT LOC INFO
                        </h3>
                        <div
                          className="my-3 m-auto d-flex"
                          style={{ width: "350px" }}
                        >
                          <div className="w-full me-2">
                            <label
                              className="form-label"
                              style={{ color: "#0987B1" }}
                            >
                              Download Template
                            </label>
                            <select
                              className="form-control"
                              name="Category"
                              id="Category"
                              placeholder="Category"
                              onChange={(e) => {
                                setSelectedTemplate(e.target.value);
                              }}
                              value={selectedTemplate}
                            >
                              <option value="default" disabled={true}>
                                Choose a category
                              </option>
                              <option value="">Default</option>
                              {categories.length !== 0 &&
                                categories
                                  .filter(
                                    (category) => category.LOC_type === type
                                  )
                                  .map((category) => (
                                    <option value={category.id}>
                                      {category.name}
                                    </option>
                                  ))}
                            </select>
                          </div>
                          <button
                            disabled={selectedTemplate === "default"}
                            onClick={(e) => handleDownloadTemplate(e, type)}
                            className=" border-0 bg-white mt-4 pt-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 52 52"
                              fill="none"
                            >
                              <path
                                d="M49.4 31.2C48.7104 31.2 48.0491 31.4739 47.5615 31.9615C47.0739 32.4491 46.8 33.1104 46.8 33.8V44.2C46.8 44.8896 46.5261 45.5509 46.0385 46.0385C45.5509 46.5261 44.8896 46.8 44.2 46.8H7.8C7.11044 46.8 6.44912 46.5261 5.96152 46.0385C5.47393 45.5509 5.2 44.8896 5.2 44.2V33.8C5.2 33.1104 4.92607 32.4491 4.43848 31.9615C3.95088 31.4739 3.28956 31.2 2.6 31.2C1.91044 31.2 1.24912 31.4739 0.761522 31.9615C0.273928 32.4491 0 33.1104 0 33.8V44.2C0 46.2687 0.821784 48.2526 2.28457 49.7154C3.74735 51.1782 5.73131 52 7.8 52H44.2C46.2687 52 48.2526 51.1782 49.7154 49.7154C51.1782 48.2526 52 46.2687 52 44.2V33.8C52 33.1104 51.7261 32.4491 51.2385 31.9615C50.7509 31.4739 50.0896 31.2 49.4 31.2ZM24.154 35.646C24.4013 35.8827 24.6928 36.0683 25.012 36.192C25.3232 36.3296 25.6597 36.4006 26 36.4006C26.3403 36.4006 26.6768 36.3296 26.988 36.192C27.3072 36.0683 27.5987 35.8827 27.846 35.646L38.246 25.246C38.7356 24.7564 39.0106 24.0924 39.0106 23.4C39.0106 22.7076 38.7356 22.0436 38.246 21.554C37.7564 21.0644 37.0924 20.7894 36.4 20.7894C35.7076 20.7894 35.0436 21.0644 34.554 21.554L28.6 27.534V2.6C28.6 1.91044 28.3261 1.24912 27.8385 0.761522C27.3509 0.273928 26.6896 0 26 0C25.3104 0 24.6491 0.273928 24.1615 0.761522C23.6739 1.24912 23.4 1.91044 23.4 2.6V27.534L17.446 21.554C16.9564 21.0644 16.2924 20.7894 15.6 20.7894C14.9076 20.7894 14.2436 21.0644 13.754 21.554C13.2644 22.0436 12.9894 22.7076 12.9894 23.4C12.9894 24.0924 13.2644 24.7564 13.754 25.246L24.154 35.646Z"
                                fill="#212F54"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className=" d-flex justify-content-center">
                          <p className="text-xs text-center text-success">
                            *note: This step is optional if you already have a
                            category specific sheet
                          </p>
                        </div>
                        <div className="d-flex justify-content-center">
                          <hr className="m-0 w-75 border-0"></hr>
                        </div>
                        <div
                          className="flex justify-center mx-auto mt-3"
                          style={{ width: "350px" }}
                        >
                          <div className="me-2">
                            <label
                              className="form-label"
                              style={{ color: "#0987B1" }}
                            >
                              Upload File
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="file"
                              name="file"
                              onChange={(e) => fileHandler(e)}
                              placeholder="excel sheet"
                              accept=".xls,.xlsx"
                            />
                          </div>
                        </div>
                        <p className="text-center text-xs text-success mt-2">
                          *note: Only .xlsx files can be uploaded
                        </p>
                        <div className="d-flex justify-content-center mb-5">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm w-25"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal>
              {/* End Model */}
            </div>
          </>
        )}
        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="my-3">Unassigned LOC’s</h3>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="w-75 m-auto my-4"
                style={{ position: "relative" }}
              >
                <i
                  className="far fa-search text-dark"
                  style={{ position: "absolute", top: "30%", left: "1%" }}
                ></i>
                <input
                  style={{ paddingLeft: 30 }}
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="Search by..."
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setSelectedSingleLocs([]);
                    setSelectedDualLocs([]);
                    dispatch(
                      onSearchingLoc(
                        singleLocs,
                        dualLocs,
                        e.target.value,
                        LOCSearchType
                      )
                    );
                  }}
                />
              </div>

              <div className="inline-block relative w-64 ml-2">
                <select
                  onChange={(e) => {
                    setSearchValue("");
                    setSelectedSingleLocs([]);
                    setSelectedDualLocs([]);
                    dispatch(
                      onSearchingLoc(singleLocs, dualLocs, "", e.target.value)
                    );
                    setLOCSearchType(e.target.value);
                  }}
                  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="unique_asset_id">
                    Unique Asset ID / Route ID
                  </option>
                  <option value="actual_asset_name">
                    Unique Asset Name / Origin
                  </option>
                  <option value="field_1">FIELD 1</option>
                  <option value="field_2">FIELD 2</option>
                  <option value="field_3">FIELD 3</option>
                  <option value="notes">NOTES</option>
                  <option value="email">LAST UPDATED BY</option>
                  <option value="destination">DESTINATION</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : singleLocs && singleLocs?.length > 0 ? (
            <div className="row">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2 font-bold mb-3">Single LOC’s</h5>
                {selectedSingleLocs?.length !== 0 && (
                  <button
                    onClick={() => setDeleteMultiIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-danger border-none text-white"
                  >
                    Delete selected single LOCs
                  </button>
                )}
              </div>
              <div className="table-responsive text-center px-0">
                <div
                  className="accordion accordion-flush border border-bottom-1"
                  id="accordionSingle"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSingleOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSingleOne"
                        aria-expanded="true"
                        aria-controls="collapseSingleOne"
                      >
                        Default
                      </button>
                    </h2>
                    <div
                      id="collapseSingleOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingSingleOne"
                      data-bs-parent="#accordionSingle"
                    >
                      <div
                        className="accordion-body p-0"
                        style={{
                          maxHeight: 400,
                          overflowY: "auto",
                          overflowX: "auto",
                        }}
                      >
                        <SingleLocsTable
                          role={role}
                          searchSingleLocs={searchSingleLocs}
                          searchValue={searchValue}
                          singleLocs={singleLocs?.filter(
                            (loc) => loc.category === null
                          )}
                          selectedSingleLocs={selectedSingleLocs}
                          onSelectLoc={onSelectLoc}
                          onSelectAllSingleLocs={onSelectAllSingleLocs}
                          sortTable={sortTable}
                          sortType={sortType}
                          locSorted={locSorted}
                          renderedItem={renderedItem}
                          notUser={notUser}
                          setSingleLocID={setSingleLocID}
                          setDeleteSingleIsOpen={setDeleteSingleIsOpen}
                          handleOpenLockModal={handleOpenLockModal}
                          notAdminOrUser={notAdminOrUser}
                          loadSearch={loadSearch}
                        />
                      </div>
                    </div>
                  </div>
                  {categories
                    .filter((category) => category.LOC_type === "single")
                    .map((category) => (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`headingSingle-${category.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseSingle-${category.id}`}
                            aria-expanded="false"
                            aria-controls={`collapseSingle-${category.id}`}
                          >
                            {category.name}
                          </button>
                        </h2>
                        <div
                          id={`collapseSingle-${category.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`headingSingle-${category.id}`}
                          data-bs-parent="#accordionSingle"
                        >
                          <div
                            class="accordion-body p-0"
                            style={{
                              overflowX: "auto",
                              overflowY: "auto",
                              maxHeight: 400,
                            }}
                          >
                            <DynamicSingleLocsTable
                              role={role}
                              searchSingleLocs={searchSingleLocs}
                              searchValue={searchValue}
                              singleLocs={singleLocs?.filter(
                                (loc) => loc.category === category.id
                              )}
                              selectedSingleLocs={selectedSingleLocs}
                              onSelectLoc={onSelectLoc}
                              onSelectAllSingleLocs={onSelectAllSingleLocs}
                              sortTable={sortTable}
                              sortType={sortType}
                              locSorted={locSorted}
                              renderedItem={renderedItem}
                              notUser={notUser}
                              setSingleLocID={setSingleLocID}
                              setDeleteSingleIsOpen={setDeleteSingleIsOpen}
                              handleOpenLockModal={handleOpenLockModal}
                              notAdminOrUser={notAdminOrUser}
                              loadSearch={loadSearch}
                              fields={category.fields}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No Single LOC’s added yet
            </h1>
          )}

          {loadingLocs ? (
            <div style={{ textAlign: "center" }}>
              <div className="spinner-border" role="status">
                {/* <span className="sr-only">Loading...</span> */}
              </div>
            </div>
          ) : dualLocs && dualLocs.length > 0 ? (
            <div className="row">
              <div className="d-flex align-items-center mt-4 justify-content-between">
                <h5 className="mr-2  font-bold mb-3">Dual LOC’s</h5>
                {selectedDualLocs.length !== 0 && (
                  <button
                    onClick={() => setDeleteMultiDualIsOpen(true)}
                    className="mb-2 text-center rounded py-1 focus:outline-none bg-danger border-none text-white"
                  >
                    Delete selected dual LOCs
                  </button>
                )}
              </div>
              <div className="table-responsive col-12 text-center pb-5 px-0">
                <div
                  className="accordion accordion-flush border border-bottom-1"
                  id="accordionDual"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingDualOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseDualOne"
                        aria-expanded="true"
                        aria-controls="collapseDualOne"
                      >
                        Default
                      </button>
                    </h2>
                    <div
                      id="collapseDualOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingDualOne"
                      data-bs-parent="#accordionDual"
                    >
                      <div
                        className="accordion-body p-0"
                        style={{
                          maxHeight: 400,
                          overflowY: "auto",
                          overflowX: "auto",
                        }}
                      >
                        <DualLocsTable
                          role={role}
                          searchDualLocs={searchDualLocs}
                          searchValue={searchValue}
                          dualLocs={dualLocs.filter(
                            (loc) => loc.category === null
                          )}
                          selectedDualLocs={selectedDualLocs}
                          onSelectLoc={onSelectLoc}
                          onSelectAllDualLocs={onSelectAllDualLocs}
                          sortTable={sortTable}
                          sortType={sortType}
                          locSorted={locSorted}
                          renderedItem={renderedItem}
                          notUser={notUser}
                          setDualLocID={setDualLocID}
                          setDeleteDualIsOpen={setDeleteDualIsOpen}
                          notAdminOrUser={notAdminOrUser}
                          loadSearch={loadSearch}
                          handleOpenLockModal={handleOpenLockModal}
                          softLockOpen={softLockOpen}
                          selectedLoc={selectedLoc}
                          setSoftLockOpen={setSoftLockOpen}
                          setSelectedLoc={setSelectedLoc}
                          handleCloseModal={handleCloseModal}
                          handleToggleSoftLock={handleToggleSoftLock}
                        />
                      </div>
                    </div>
                  </div>
                  {categories
                    .filter((category) => category.LOC_type === "dual")
                    .map((category) => (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`headingDual-${category.id}`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseDual-${category.id}`}
                            aria-expanded="false"
                            aria-controls={`collapseDual-${category.id}`}
                          >
                            {category.name}
                          </button>
                        </h2>
                        <div
                          id={`collapseDual-${category.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`headingDual-${category.id}`}
                          data-bs-parent="#accordionDual"
                        >
                          <div
                            class="accordion-body p-0"
                            style={{
                              maxHeight: 400,
                              overflowY: "auto",
                              overflowX: "auto",
                            }}
                          >
                            <DynamicDualLocsTable
                              role={role}
                              searchDualLocs={searchDualLocs}
                              searchValue={searchValue}
                              dualLocs={dualLocs.filter(
                                (loc) => loc.category === category.id
                              )}
                              selectedDualLocs={selectedDualLocs}
                              onSelectLoc={onSelectLoc}
                              onSelectAllDualLocs={onSelectAllDualLocs}
                              sortTable={sortTable}
                              sortType={sortType}
                              locSorted={locSorted}
                              renderedItem={renderedItem}
                              notUser={notUser}
                              setDualLocID={setDualLocID}
                              setDeleteDualIsOpen={setDeleteDualIsOpen}
                              notAdminOrUser={notAdminOrUser}
                              loadSearch={loadSearch}
                              handleOpenLockModal={handleOpenLockModal}
                              softLockOpen={softLockOpen}
                              selectedLoc={selectedLoc}
                              setSoftLockOpen={setSoftLockOpen}
                              setSelectedLoc={setSelectedLoc}
                              handleCloseModal={handleCloseModal}
                              handleToggleSoftLock={handleToggleSoftLock}
                              fields={category.fields}
                              destination_fields={category.destination_fields}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <h1 style={{ textAlign: "center", margin: "20px 0" }}>
              No dual LOC’s added yet
            </h1>
          )}
        </div>
      </div>
      {/* Delete Modal Single*/}
      <Modal
        isOpen={DeleteSingleIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteSingleIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingLoc(e, singleLocID, token, "single", gid)
                    );
                    setDeleteSingleIsOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteSingleIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal Single */}

      {/* Delete Modal Dual*/}
      <Modal
        isOpen={DeleteDualIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteDualIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete this?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(onDeletingLoc(e, dualLocID, token, "dual", gid));
                    setDeleteDualIsOpen(false);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Delete Modal Dual */}

      {/* Delete Multi Modal*/}
      <Modal
        isOpen={DeleteMultiIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteMultiIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingSelectedLoc(
                        e,
                        selectedSingleLocs,
                        [],
                        dualLocs,
                        singleLocs,
                        token,
                        gid
                      )
                    );
                    setDeleteMultiIsOpen(false);
                    setSelectedSingleLocs([]);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteMultiIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Multi Dual Modal*/}
      <Modal
        isOpen={DeleteMultiDualIsOpen}
        style={customStyles}
        onRequestClose={() => setDeleteMultiDualIsOpen(false)}
        contentLabel="Delete Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to delete all selected LOCs?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={(e) => {
                    dispatch(
                      onDeletingSelectedLoc(
                        e,
                        [],
                        selectedDualLocs,
                        dualLocs,
                        singleLocs,
                        token,
                        gid
                      )
                    );
                    setDeleteMultiDualIsOpen(false);
                    setSelectedDualLocs([]);
                  }}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => setDeleteMultiDualIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* soft lock  */}
      <Modal
        isOpen={softLockOpen}
        style={customStyles}
        onRequestClose={handleCloseModal}
        contentLabel="Soft Lock Modal"
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
      >
        <div className="container modal-body">
          <div className="row">
            <div className="col-10 m-auto">
              <h5 className="text-center my-3">
                Are you sure you want to{" "}
                {selectedLoc?.soft_lock ? "deactivate" : "activate"} soft lock
                for ({selectedLoc?.actual_asset_name})?
              </h5>

              <div className="d-flex justify-content-center my-3">
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={handleToggleSoftLock}
                >
                  {selectedLoc?.soft_lock ? "Deactivate" : "Activate"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default UnAssignedLocs;
