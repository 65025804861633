// ProductGridCard.jsx
import React from 'react';
import { toast } from 'react-toastify';
import ProceedToCheckout from '../ProceedToCheckout';
import { ShoppingCart } from 'lucide-react';
import { useCartActions } from "../../../hooks/useCartActions";
import './index.css';

const ProductGridCard = ({ product, onProductSelect, onAddToCart, onRemoveFromCart }) => {
  const { cart, addToCart, getTotalCartQuantity, maxQuantity } = useCartActions();
  const count = getTotalCartQuantity();
  const getBadgeColor = (badge) => {
    const badgeClasses = {
      'New': 'badge-new',
      'Sale': 'badge-sale',
      'Limited': 'badge-limited'
      // Add more badge types as needed
    };
    return badgeClasses[badge] || 'badge-default';
  };

  const handleAddToCart = (product, quantity) => {
    addToCart({ ...product, quantity });
  };


  return (
    <div className="grid-card-container">
      <div className="grid-card">
        <div className="card-content">
          <div 
            className="image-container"
            onClick={() => onProductSelect(product)}
          >
            <img
              src={product.image || "/placeholder.svg"}
              alt={product.name}
              className="product-image"
            />
            {product.badge && (
              <div className={`badge ${getBadgeColor(product.badge)}`}>
                {product.badge}
              </div>
            )}
          </div>
          <div className="details-container">
            <div className="header">
              <h3 className="product-name">{product.name}</h3>
              <span className="tag-count">50 NFC Tags</span>
            </div>
            <p className="description">{product.description}</p>
            <div className="footer">
              <p className="price">${product.price}</p>
              {cart.find((item) => item.id === product.id) ? (
                <button 
                className="add-cart-button-disabled"
                disabled={true}
              >
                <ShoppingCart className="cart-icon" />
                Added to Cart
              </button>
              ) : (
                <button 
                  className={`${count >= maxQuantity ? 'add-cart-button-disabled' : 'add-cart-button'}`}
                  disabled= {count >= maxQuantity}
                  onClick={() => {
                    handleAddToCart(product, 1);
                    toast(() => <ProceedToCheckout />)
                  }}
                >
                  <ShoppingCart className="cart-icon" />
                  Add to Cart
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductGridCard;