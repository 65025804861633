import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";
import ProductGridCard from "./ProductGridCard";

import { url, notAuthError } from "../../constants";
import { useCartActions } from "../../hooks/useCartActions";

// Main Purchase Page Component
const PurchasePage = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const { addToCart, removeFromCart } = useCartActions();

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      fetch(`${url}/api/stripe/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
          web: true,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            if (data.error === notAuthError) {
              toast("you session expired, please login again.");
            } else {
              toast.error(data.error);
            }
          } else {
            setProducts(data.Products);
          }
        }).then(() => {
          setLoading(false);
        });
    }, [token]);

  const handleProductSelect = (product) => {
    navigate(`/buy-tags/${product.name}`);
  };

  const handleAddToCart = (product, quantity) => {
    addToCart({ ...product, quantity });
  };

  if (loading) {
    return (                 
    <PropagateLoader
      color={"#0089B8"}
      css={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      size={50}
    />
    )
  }

  return (
    <div className="pt-5 pb-2">
      <h1 className="font-bold text-center text-[#0089B8]">Our Products</h1>
      <div className="2xl:px-48 md:px-4 pt-24 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {products.map((product) => {
          return (
            <ProductGridCard 
              key={product.id}
              product={product}
              onProductSelect={(product) => handleProductSelect(product)}
              onAddToCart={(product, quantity) => handleAddToCart(product, quantity)}
              onRemoveFromCart={(product) => removeFromCart(product.id)}
            />
          )
        })}
      </div>
    </div>
  );
};

export default PurchasePage;
