import { ACTIONS } from '../contexts/cart/cartActions';
import { useCart, useCartDispatch } from '../contexts/cart/cartContext';
import { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';

let hasWarnedAboutQuantity = false;

const calculateMaxQuantity = (quota) => {
  let lite = localStorage.getItem('lite') === 'true';
  const maxQuantity = 40 - Math.ceil(Number(quota) / 50);
  if (maxQuantity <= 0 && !hasWarnedAboutQuantity && lite) {
    toast.warn('Maximum LOC quantity has been reached. Please contact support.');
    hasWarnedAboutQuantity = true;
  }
  return maxQuantity;
};

export function useCartActions() {
  const cart = useCart();
  const dispatch = useCartDispatch();
  const [error, setError] = useState('');
  
  const MAX_CART_QUANTITY = useMemo(() => {
    const token = localStorage.getItem('token');
    if (!token) return 40;
    
    const quota = localStorage.getItem('quota');
    return calculateMaxQuantity(quota || 40);
  }, []);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token' || e.key === 'quota') {
        hasWarnedAboutQuantity = false;
        dispatch({ type: ACTIONS.UPDATE_MAX_QUANTITY });
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [dispatch]);

  const getTotalCartQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };
  
  const addToCart = (product) => {
    const currentTotal = getTotalCartQuantity();
    
    if (currentTotal + 1 > MAX_CART_QUANTITY) {
      setError(`Cart quantity cannot exceed ${MAX_CART_QUANTITY} items`);
      return false;
    }
    
    setError('');
    dispatch({ type: ACTIONS.ADD_TO_CART, payload: product });
    return true;
  };
  
  const removeFromCart = (productId) => {
    setError('');
    dispatch({ type: ACTIONS.REMOVE_FROM_CART, payload: { id: productId } });
  };
  
  const updateQuantity = (productId, quantity) => {
    const currentItem = cart.find(item => item.id === productId);
    const otherItemsQuantity = getTotalCartQuantity() - (currentItem?.quantity || 0);
    
    if (otherItemsQuantity + quantity > MAX_CART_QUANTITY) {
      setError(`Cart quantity cannot exceed ${MAX_CART_QUANTITY} items`);
      return false;
    }
    
    setError('');
    dispatch({ 
      type: ACTIONS.UPDATE_QUANTITY, 
      payload: { id: productId, quantity: quantity }
    });
    return true;
  };
  
  const clearCart = () => {
    dispatch({ type: ACTIONS.CLEAR_CART });
  };
  
  const getCartTotal = () => {
    return cart.reduce((total, item) => total + (item.price * item.quantity), 0);
  };
  
  return {
    cart,
    getTotalCartQuantity,
    addToCart,
    removeFromCart,
    updateQuantity,
    clearCart,
    getCartTotal,
    error,
    maxQuantity: MAX_CART_QUANTITY
  };
}