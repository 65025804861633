import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  onAddingProject,
  onChangeProjectInputs,
} from "../../store/Projects/ProjectsReducer";
import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectsMap from "../Map/ProjectMap";
import { notAuthError, url } from "../../constants";
import useUsers from "../../hooks/project/useUsers";
import useThirdParty from "../../hooks/thirdParty/useThirdParty";
import roles from "../../util/roles";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const AddNewProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disableBtn, setDisableBtn] = useState(false);
  const { id } = useParams();
  const { token, role } = useSelector((state) => state.login);
  const { projectForm, identifierId, loadAdding } = useSelector(
    (state) => state.projects
  );
  const [orgId, setOrgId] = useState(null);
  const { loading: usersLoading, users } = useUsers(
    identifierId,
    "available-users"
  );
  const loggedInUser = localStorage.getItem("userId");
  const loggedInThirdParty = localStorage.getItem("thirdParty_id");
  let filteredUsers = users;
  if(loggedInThirdParty) {
    filteredUsers = users.filter(user => loggedInThirdParty === user.thirdParty_id);
  }

  const { thirdParties, loading: thirdPartiesLoading } = useThirdParty(
    orgId,
    !!orgId
  );

  const styleLinkBack = {
    textDecoration: "none",
    color: "#717993",
    fontSize: "22px",
  };

  const [radius, setRadius] = useState(10);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);

  useEffect(() => {
    fetch(`${url}/api/globalIdentifiers/${id}/projects`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setOrgId(resData.globalIdentifier.org_id);
        }
      });
  }, []);

  const [assignedUsers, setAssignedUsers] = useState([]);
  const [assignedThirdParties, setAssignedThirdParties] = useState([]);

  const onAssignUser = (userId) => {
    if (!assignedUsers.includes(userId)) {
      setAssignedUsers([...assignedUsers, userId]);
    } else {
      setAssignedUsers(assignedUsers.filter((user) => user !== userId));
    }
  };

  const onAssignThirdParty = (thirdPartyId) => {
    let usersInThirdParty = users.filter(user => user.thirdParty_id === thirdPartyId);
    usersInThirdParty = usersInThirdParty.map(user => {
      return user.user_id
    })
    if(!assignedThirdParties.includes(thirdPartyId)) {
      setAssignedThirdParties([...assignedThirdParties, thirdPartyId]);
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            // updatedState.splice(index, 1);
          } else {
            updatedState.push(userId);
          };
        })
        return updatedState;
      })
    } else {
      setAssignedThirdParties(assignedThirdParties.filter((thirdParty) => thirdParty !== thirdPartyId));
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            updatedState.splice(index, 1);
          }
          //  else {
          //   updatedState.push(userId);
          // };
        })
        return updatedState;
      })
    }
  };


  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        {globalIdentifier?.name ? (
          <Link
            to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
            style={styleLinkBack}
          >
            {globalIdentifier.name}
          </Link>
        ) : null}
        {/* <Link to={"/gid"} style={styleLinkBack}>
          GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          ADD NEW Project
        </Link> */}
        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="text-center my-3">ADD NEW PROJECT </h3>
            <form
              onSubmit={(e) =>
                dispatch(
                  onAddingProject(
                    e,
                    token,
                    projectForm.name.value,
                    projectForm.lat.value,
                    projectForm.long.value,
                    projectForm.radius.value,
                    projectForm.privacy.value,
                    assignedUsers,
                    id,
                    navigate
                  )
                )
              }
            >
              <input
                type="text"
                className="form-control"
                placeholder="Add Project Name"
                onChange={(e) =>
                  dispatch(onChangeProjectInputs(e.target.value, "name"))
                }
                value={projectForm.name.value}
                style={
                  projectForm.name.valid ? {} : { border: "1px solid red" }
                }
              />
              {projectForm.name.valid ? null : (
                <div style={{ color: "red", fontSize: 14 }}>
                  {projectForm.name.validationError}
                </div>
              )}
              <ProjectsMap radius={radius} />
              {/* <div className="bg-light mt-3">Map</div> */}
              <div className="row my-3">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="Latitude"
                    placeholder="Latitude"
                    disabled={true}
                    onChange={(e) =>
                      dispatch(
                        onChangeProjectInputs(Number(e.target.value), "lat")
                      )
                    }
                    value={projectForm.lat.value}
                    style={true ? {} : { border: "1px solid red" }}
                  />
                  {true ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {projectForm.lat.validationError}
                    </div>
                  )}
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="Longitude"
                    placeholder="Longitude"
                    disabled={true}
                    onChange={(e) =>
                      dispatch(
                        onChangeProjectInputs(Number(e.target.value), "long")
                      )
                    }
                    value={projectForm.long.value}
                    style={true ? {} : { border: "1px solid red" }}
                  />
                  {true ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {projectForm.long.validationError}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  type="number"
                  step={1}
                  min={0}
                  className="form-control"
                  name="Radius"
                  placeholder="Radius"
                  id="Radius"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      dispatch(onChangeProjectInputs(e.target.value, "radius"));
                      setRadius(e.target.value);
                    }
                  }}
                  value={
                    projectForm.radius.value
                  }
                />
                <span style={{ marginLeft: 5 }}>Meter</span>
              </div>

              {/* {projectForm.radius.valid ? null : (
                <div style={{ color: "red", fontSize: 14 }}>
                  {projectForm.radius.validationError}
                </div>
              )} */}

              <div className="flex items-center mt-2">
                <input
                  onChange={(e) => {
                    if (projectForm.privacy.value === "private") {
                      dispatch(onChangeProjectInputs("public", "privacy"));
                      if(assignedUsers.includes(loggedInUser) && filteredUsers.some(user => user.user_id === loggedInUser)){
                        onAssignUser(loggedInUser);
                      }
                    } else {
                      dispatch(onChangeProjectInputs("private", "privacy"));
                      if(!assignedUsers.includes(loggedInUser) && filteredUsers.some(user => user.user_id === loggedInUser)){
                        onAssignUser(loggedInUser);
                      }
                    }
                  }}
                  checked={projectForm.privacy.value === "private"}
                  id="checked-checkbox"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium"
                >
                  Private
                </label>
              </div>

              {!usersLoading &&
                users.length > 0 &&
                projectForm.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <div className="mt-4">
                    {thirdParties.length > 0 ? (
                      <>
                        <h6>Assigned Third Parties</h6>

                        <div
                          className="mt-2"
                          style={{ height: "100px", overflowY: "scroll" }}
                        >
                          {thirdParties.map((thirdParty) => (
                            <>
                              {role === roles.saas_admin ||
                              role === roles.super_user ||
                              role === roles.super_admin ? (
                                <div
                                  key={thirdParty.user_id}
                                  className="flex items-center my-2 mr-2"
                                >
                                  <input
                                    onChange={() => onAssignThirdParty(thirdParty.id)}
                                    checked={assignedThirdParties.includes(thirdParty.id)}
                                    id={thirdParty.id}
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <label
                                    htmlFor={thirdParty.id}
                                    className="ml-2 text-sm font-medium"
                                  >
                                    {thirdParty.name}
                                  </label>
                                </div>
                              ) : (
                                <label
                                  htmlFor="checked-checkbox"
                                  className="text-sm font-medium"
                                >
                                  {projectForm.privacy.value === "private"
                                    ? "Private"
                                    : "Public"}
                                </label>
                              )}
                            </>
                          ))}
                        </div>
                      </>
                    ) : ""}
                    <h6>Assigned Users</h6>

                    <div
                      className="mt-2"
                      style={{ height: "150px", overflowY: "scroll" }}
                    >
                      {filteredUsers.map((user) => (
                        <>
                          {role === roles.saas_admin ||
                          role === roles.super_user ||
                          role === roles.super_admin ? (
                            <div
                              key={user.user_id}
                              className="flex items-center my-2 mr-2"
                            >
                              <input
                                onChange={() => onAssignUser(user.user_id)}
                                checked={assignedUsers.includes(user.user_id)}
                                id={user.user_id}
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor={user.user_id}
                                className="ml-2 text-sm font-medium"
                              >
                                {user.fullName}
                              </label>
                            </div>
                          ) : (
                            <label
                              htmlFor="checked-checkbox"
                              className="text-sm font-medium"
                            >
                              {projectForm.privacy.value === "private"
                                ? "Private"
                                : "Public"}
                            </label>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}

              {users.length === 0 &&
                projectForm.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <p className="fw-bold my-2 text-center">
                    there are no users in this project
                  </p>
                )}

              <div className="d-flex justify-content-center my-3">
                <button
                  disabled={
                    disableBtn
                      ? disableBtn
                      : !(
                          (
                            projectForm.name.valid &&
                            projectForm.lat.valid &&
                            projectForm.long.valid
                          )
                          // && projectForm.radius.valid
                        )
                  }
                  type="submit"
                  className="btn btn-primary w-25"
                  style={{ width: "130px", height: "40px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setDisableBtn(true);
                    }, 1);
                    setTimeout(() => {
                      setDisableBtn(false);
                    }, 2000);
                  }}
                >
                  {loadAdding ? (
                    <PropagateLoader color={"#fff"} css={override} size={10} />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AddNewProject;
