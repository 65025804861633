import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/navbar/NavBar";
import Cart from "../../../components/buyTags/Cart";
import { useCartActions } from "../../../hooks/useCartActions";

const CartPage = () => {
    const navigate = useNavigate();
    const { 
      cart, 
      removeFromCart, 
      updateQuantity, 
      getCartTotal ,
      getTotalCartQuantity,
      maxQuantity
    } = useCartActions();

  return (
    <Fragment>
      <NavBar />
      <Cart 
        cart={cart} 
        onClose={() => navigate("/buy-tags/")} 
        onRemoveItem={removeFromCart} 
        onUpdateQuantity={updateQuantity}  
        getCartTotal={getCartTotal}
        getTotalCartQuantity={getTotalCartQuantity}
        maxQuantity={maxQuantity}
       />
    </Fragment>
  );
};
export default CartPage;