import { createContext, useContext, useReducer, useEffect } from 'react';
import { cartReducer } from './cartReducer';
import { ACTIONS } from './cartActions';

const CartContext = createContext(null);
const CartDispatchContext = createContext(null);
const STORAGE_KEY = 'shopping_cart';
const TOKEN_KEY = 'token';

const loadInitialState = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return [];
    }
    
    const savedCart = localStorage.getItem(STORAGE_KEY);
    return savedCart ? JSON.parse(savedCart) : [];
  } catch {
    return [];
  }
};

export function CartProvider({ children }) {
  const [cart, dispatch] = useReducer(cartReducer, null, loadInitialState);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(cart));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }, [cart]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === TOKEN_KEY && !e.newValue) {
        dispatch({ type: ACTIONS.CLEAR_CART });
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <CartContext.Provider value={cart}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (context === null) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
}

export function useCartDispatch() {
  const context = useContext(CartDispatchContext);
  if (context === null) {
    throw new Error('useCartDispatch must be used within a CartProvider');
  }
  return context;
}