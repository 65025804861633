import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  onAddingLocation,
  onChangeLocationInputs,
} from "../../store/Locations/LocationsReducers";

import { PropagateLoader } from "react-spinners";
import { css } from "@emotion/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KelTechMap from "../Map/KelTechMap";
import LocationMap from "../Map/LocationMap";
import {
  onFetchingSpecificProject,
  onSelectingProject,
} from "../../store/Projects/ProjectsReducer";

import { notAuthError, url } from "../../constants";
import roles from "../../util/roles";
import useUsers from "../../hooks/location/useUsers";
import useThirdParty from "../../hooks/thirdParty/useThirdParty";

const styleLinkBack = {
  textDecoration: "none",
  color: "#717993",
  fontSize: "22px",
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const AddNewLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const { role, token } = useSelector((state) => state.login);
  const { id } = useParams();
  const { specificProject } = useSelector((state) => state.projects);
  const { locationForm, loadAdding } = useSelector((state) => state.locations);
  const [radius, setRadius] = useState(2000);

  const [globalIdentifier, setGlobalIdenetifier] = useState(null);
  const [project, setProject] = useState(null);
  const [orgId, setOrgId] = useState(null);

  const { users, loading: usersLoading } = useUsers(
    specificProject?.id,
    "available-users"
  );

  const { thirdParties, loading: thirdPartiesLoading } = useThirdParty(
    orgId,
    !!orgId
  );

  const loggedInUser = localStorage.getItem("userId");

  const filteredThirdParties = thirdParties.filter(thirdParty => users.some(user => user.thirdParty_id === thirdParty.id))

  const [assignedUsers, setAssignedUsers] = useState([]);
  const [assignedThirdParties, setAssignedThirdParties] = useState([]);

  const onAssignUser = (userId) => {
    if (!assignedUsers.includes(userId)) {
      setAssignedUsers([...assignedUsers, userId]);
    } else {
      setAssignedUsers(assignedUsers.filter((user) => user !== userId));
    }
  };

  useEffect(() => {
    fetch(`${url}/api/projects/${id}/locations`, {
      headers: { Authorization: `Bearer ${token}`, web: true },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            toast.error(resData.error);
          }
        } else {
          setGlobalIdenetifier(resData.globalIdentifier);
          setProject(resData.project);
          setOrgId(resData.globalIdentifier.org_id);
        }
      });

    dispatch(onFetchingSpecificProject(id, token));
  }, []);

  const onAssignThirdParty = (thirdPartyId) => {
    let usersInThirdParty = users.filter(user => user.thirdParty_id === thirdPartyId);
    usersInThirdParty = usersInThirdParty.map(user => {
      return user.user_id
    })
    if(!assignedThirdParties.includes(thirdPartyId)) {
      setAssignedThirdParties([...assignedThirdParties, thirdPartyId]);
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            // updatedState.splice(index, 1);
          } else {
            updatedState.push(userId);
          };
        })
        return updatedState;
      })
    } else {
      setAssignedThirdParties(assignedThirdParties.filter((thirdParty) => thirdParty !== thirdPartyId));
      setAssignedUsers(prevState => {
        let updatedState = [...prevState];
        usersInThirdParty.forEach(userId => {
          const index = updatedState.findIndex(item => item === userId);
          if(index > -1) {
            updatedState.splice(index, 1);
          }
          //  else {
          //   updatedState.push(userId);
          // };
        })
        return updatedState;
      })
    }
  };


  return (
    <Fragment>
      <div className="container">
        <ToastContainer />
        {globalIdentifier?.name && project?.name ? (
          <Fragment>
          <Link
            to={"/globalidenetifiers"}
            style={styleLinkBack}
          >
            Global Identifiers
          </Link>
          <span className="mx-2" style={{ color: "#28345C" }}>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </span>
            <Link
              to={"/globalidenetifiers/projects/" + globalIdentifier.gid}
              style={styleLinkBack}
            >
              {globalIdentifier.name}
            </Link>
            <span className="mx-2" style={{ color: "#28345C" }}>
              <i className="fas fa-chevron-right"></i>
              <i className="fas fa-chevron-right"></i>
            </span>
            <Link to={"/locations/" + project.id} style={styleLinkBack}>
              {project.name}
            </Link>
          </Fragment>
        ) : null}
        {/* <Link to={"/gid"} style={styleLinkBack}>
          GID 1
        </Link>
        <span className="mx-2" style={{ color: "#28345C" }}>
          <i className="fas fa-chevron-right"></i>
          <i className="fas fa-chevron-right"></i>
        </span>
        <Link to={"/gid"} style={styleLinkBack}>
          ADD NEW LOCATION
        </Link> */}
        <div className="row">
          <div className="col-12 m-auto">
            <h3 className="text-center my-3">NEW LOCATION</h3>
            <form
              onSubmit={(e) =>
                dispatch(
                  onAddingLocation(
                    e,
                    token,
                    locationForm.name.value,
                    locationForm.lat.value,
                    locationForm.long.value,
                    locationForm.radius.value,
                    locationForm.privacy.value,
                    assignedUsers,
                    id,
                    globalIdentifier.gid,
                    navigate
                  )
                )
              }
            >
              <input
                type="text"
                className="form-control"
                placeholder="Add Location Name"
                value={locationForm.name.value}
                onChange={(e) =>
                  dispatch(onChangeLocationInputs(e.target.value, "name"))
                }
                style={
                  locationForm.name.valid ? {} : { border: "1px solid red" }
                }
              />
              {locationForm.name.valid ? null : (
                <div style={{ color: "red", fontSize: 14 }}>
                  {locationForm.name.validationError}
                </div>
              )}
              {/* <div className="bg-light mt-3">Map</div> */}
              <LocationMap
                radius={radius}
                projectLat={specificProject && specificProject.latitude}
                projectLong={specificProject && specificProject.longitude}
              />

              <div className="row my-3">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="Latitude"
                    placeholder="Latitude"
                    disabled={true}
                    onChange={(e) =>
                      dispatch(
                        onChangeLocationInputs(Number(e.target.value), "lat")
                      )
                    }
                    value={locationForm.lat.value}
                    style={true ? {} : { border: "1px solid red" }}
                  />
                  {true ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {locationForm.lat.validationError}
                    </div>
                  )}
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="Longitude"
                    placeholder="Longitude"
                    disabled={true}
                    onChange={(e) =>
                      dispatch(
                        onChangeLocationInputs(Number(e.target.value), "long")
                      )
                    }
                    value={locationForm.long.value}
                    style={true ? {} : { border: "1px solid red" }}
                  />
                  {true ? null : (
                    <div style={{ color: "red", fontSize: 14 }}>
                      {locationForm.long.validationError}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  // justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  type="number"
                  step={1}
                  min={0}
                  className="form-control"
                  name="Radius"
                  placeholder="Radius"
                  id="Radius"
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      dispatch(onChangeLocationInputs(e.target.value, "radius"));
                      setRadius(e.target.value);
                    }
                  }}
                  value={
                    locationForm.radius.value !== ""
                      // ? locationForm.radius.value
                      // : 1
                  }
                  // style={
                  //   locationForm.radius.valid ? {} : { border: "1px solid red" }
                  // }
                />
                <span style={{ marginLeft: 5 }}>Meter</span>
              </div>

              {/* {locationForm.radius.valid ? null : (
                <div style={{ color: "red", fontSize: 14 }}>
                  {locationForm.radius.validationError}
                </div>
              )} */}

              <div className="flex items-center mt-2">
                <input
                  onChange={(e) => {
                    if (locationForm.privacy.value === "private") {
                      dispatch(onChangeLocationInputs("public", "privacy"));
                      if(assignedUsers.includes(loggedInUser) && users.some(user => user.user_id === loggedInUser)){
                        onAssignUser(loggedInUser);
                      }
                    } else {
                      dispatch(onChangeLocationInputs("private", "privacy"));
                      if(!assignedUsers.includes(loggedInUser) && users.some(user => user.user_id === loggedInUser)){
                        onAssignUser(loggedInUser);
                      }
                    }
                  }}
                  checked={locationForm.privacy.value === "private"}
                  id="checked-checkbox"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="checked-checkbox"
                  className="ml-2 text-sm font-medium"
                >
                  Private
                </label>
              </div>
              {!usersLoading &&
                users.length > 0 &&
                locationForm.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <div className="mt-4">
                    {filteredThirdParties.length > 0 ? (
                      <>
                        <h6>Assigned Third Parties</h6>

                        <div
                            className="mt-2"
                            style={{ height: "100px", overflowY: "scroll" }}
                          >
                            {filteredThirdParties.map((thirdParty) => (
                              <>
                                {role === roles.saas_admin ||
                                role === roles.super_user ||
                                role === roles.super_admin ? (
                                  <div
                                    key={thirdParty.user_id}
                                    className="flex items-center my-2 mr-2"
                                  >
                                    <input
                                      onChange={() => onAssignThirdParty(thirdParty.id)}
                                      checked={assignedThirdParties.includes(thirdParty.id)}
                                      id={thirdParty.id}
                                      type="checkbox"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                      htmlFor={thirdParty.id}
                                      className="ml-2 text-sm font-medium"
                                    >
                                      {thirdParty.name}
                                    </label>
                                  </div>
                                ) : (
                                  <label
                                    htmlFor="checked-checkbox"
                                    className="text-sm font-medium"
                                  >
                                    {locationForm.privacy.value === "private"
                                      ? "Private"
                                      : "Public"}
                                  </label>
                                )}
                              </>
                            ))}
                          </div>
                      </>
                    ): ""}
                    <h6>Assigned Users</h6>

                    <div
                      className="mt-2"
                      style={{ height: "150px", overflowY: "scroll" }}
                    >
                      {users.map((user) => (
                        <>
                          {role === roles.saas_admin ||
                          role === roles.super_user ||
                          role === roles.super_admin ? (
                            <div
                              key={user.user_id}
                              className="flex items-center my-2 mr-2"
                            >
                              <input
                                onChange={() => onAssignUser(user.user_id)}
                                checked={assignedUsers.includes(user.user_id)}
                                id={user.user_id}
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor={user.user_id}
                                className="ml-2 text-sm font-medium"
                              >
                                {user.fullName}
                              </label>
                            </div>
                          ) : (
                            <label
                              htmlFor="checked-checkbox"
                              className="text-sm font-medium"
                            >
                              {locationForm.privacy.value === "private"
                                ? "Private"
                                : "Public"}
                            </label>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )}

              {users.length === 0 &&
                locationForm.privacy.value === "private" &&
                (role === roles.super_admin ||
                  role === roles.super_user ||
                  role === roles.saas_admin) && (
                  <p className="fw-bold my-2 text-center">
                    there are no users in this global idenetifier
                  </p>
                )}
              <div className="d-flex justify-content-center my-3">
                <button
                  disabled={
                    disableBtn
                      ? disableBtn
                      : !(
                          (
                            locationForm.name.valid &&
                            locationForm.lat.valid &&
                            locationForm.long.valid
                          )
                          //  && locationForm.radius.valid
                        )
                  }
                  type="submit"
                  className="btn btn-primary w-25"
                  style={{ width: "130px", height: "40px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setDisableBtn(true);
                    }, 1);
                    setTimeout(() => {
                      setDisableBtn(false);
                    }, 2000);
                  }}
                >
                  {loadAdding ? (
                    <PropagateLoader color={"#fff"} css={override} size={10} />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AddNewLocation;
