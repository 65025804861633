import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ExcelRenderer } from "react-excel-renderer";
import Modal from "react-modal";

import { url, notAuthError } from "../../constants";
import { ReactComponent as DeleteIcon } from "../../ui/icons/DeleteIcon.svg";
import styles from "../users/users.module.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    zIndex: 1000,
  },
};

const RedeemCodes = () => {
  const [redeemCodes, setRedeemCodes] = useState([]);
  const [numberOfCodes, setNumberOfCodes] = useState(0);
  const [numberOfLOCs, setNumberOfLOCs] = useState(1);
  const [generateCodesIsOpen, setGenerateCodesIsOpen] = useState(false);
  const [addFileIsOpen, setAddFileIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetch(`${url}/api/redeem-codes`, {
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setRedeemCodes(data.redeemCodes);
      })
      .catch((error) => {
        toast.error("An error occurred");
      });
  }, [token, flag]);

  const [state, setState] = useState();
  const [file, setFile] = useState({});
  const fileHandler = (event) => {
    let fileObj = event.target.files[0];

    setFile(fileObj);
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setState({
          cols: resp.cols,
          rows: resp.rows,
        });
      }
    });
  };

  const onUploadFile = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("LocFile", file);

    fetch(`${url}/api/redeem-codes/upload`, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, web: true },
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        if (resData.error) {
          if (resData.error === notAuthError) {
            toast("you session expired, please login again.");
          } else {
            const error = resData.error;
            if (typeof error === "string") {
              toast.error(resData.error);
            } else if (
              typeof Object.entries(resData.error).map(([k, v]) => v)[0] ===
              "string"
            ) {
              toast.error(Object.entries(resData.error).map(([k, v]) => v)[0]);
            } else {
              toast.error(
                Object.entries(resData.error).map(([k, v]) => v)[0][0]
              );
            }
          }
        } else {
          if (resData.message) {
            toast.success(resData.message);
            setFlag(true);
          }
        }

        setFlag(false);
      });
  };

  const handleDelete = (id) => {
    fetch(`${url}/api/redeem-codes/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        web: true,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Code deleted successfully");
        setRedeemCodes(
          redeemCodes.filter((redeemCode) => redeemCode.id !== id)
        );
        setFlag(f => !f);
      })
      .catch((error) => {
        toast.error("An error occurred");
      });
  };

  const handleGenerateCodes = () => {
    console.log(numberOfCodes, numberOfLOCs);
    fetch(`${url}/api/redeem-codes/generate`, {
      method: "POST",
      body: JSON.stringify({
        amount: numberOfCodes,
        LOCs: numberOfLOCs,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        web: true,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Codes generated successfully");
        setFlag(f => !f);
      })
      .catch((error) => {
        toast.error("An error occurred");
      });
  };
  return (
    <div className="container pb-5">
      <ToastContainer />
      <div className="row">
        <div className="col-12 my-4 m-auto row justify-content-between">
          <div
            className="my-4 col-6 fs-3"
            style={{ textTransform: "uppercase" }}
          >
            Redeem Codes
          </div>
          <div className="row col-6 d-flex justify-content-end my-4">
            <div className="col-6 col-md-4 w-100 d-flex justify-content-end">
              <button
                type="button"
                className="btn bg-gradient-to-r from-[#0089B8] to-[#162B46] text-white border-0 rounded-3 me-4"
                style={{ fontWeight: 700 }}
                onClick={() => {
                  setAddFileIsOpen(true);
                }}
              >
                Import Codes
              </button>
              <button
                type="button"
                className="btn bg-gradient-to-r from-[#0089B8] to-[#162B46] text-white border-0 rounded-3 py-3"
                style={{ fontWeight: 700 }}
                onClick={() => setGenerateCodesIsOpen(true)}
              >
                Generate Codes
              </button>
            </div>
          </div>
        </div>
      </div>
      {redeemCodes?.length > 0 ? (
        <div
          style={{ maxHeight: window.innerHeight - 280, overflowY: "scroll" }}
        >
          <table className="table table-striped">
            <thead className="sticky top-0">
              <tr className="bg-gradient-to-r from-[#0089B8] to-[#162B46] text-white">
                <th scope="col">#</th>
                <th scope="col">Code</th>
                <th scope="col">NO. of LOCs</th>
                <th scope="col">Status</th>
                <th scope="col">Date Created</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {redeemCodes?.map((redeemCode, index) => {
                return (
                  <tr key={redeemCode.id}>
                    <th className="align-middle" scope="row">
                      {index + 1}
                    </th>
                    <td className="align-middle font-semibold">
                      {redeemCode.text}
                    </td>
                    <td className="align-middle font-bold">
                      {redeemCode.LOCs}
                    </td>
                    <td
                      className={`align-middle font-bold text-capitalize ${
                        redeemCode.status === "redeemed"
                          ? "text-danger"
                          : "text-success"
                      }`}
                    >
                      {redeemCode.status}
                    </td>
                    <td className="align-middle font-semibold">
                      {redeemCode.createdAt.split("T")[0]}
                    </td>
                    <td>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => handleDelete(redeemCode.id)}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>No Codes Added Yet.</div>
      )}

      <Modal
        isOpen={addFileIsOpen}
        style={customStyles}
        onRequestClose={() => setAddFileIsOpen(false)}
        contentLabel="Add Modal"
        ariaHideApp={false}
        className={styles.ModalSmall}
        overlayClassName={styles.Overlay}
      >
        <div className="modal-body">
          <div className="row">
            <form
              onSubmit={(e) => {
                onUploadFile(e);
                setAddFileIsOpen(false);
                setFile({});
              }}
            >
              <div className="col">
                <h3 className="text-center mt-5 font-semibold tracking-tight bg-gradient-to-r from-[#0089B8] to-[#162B46] bg-clip-text text-transparent">
                  Import Codes
                </h3>
                <div className="d-flex justify-content-center">
                  {file && file.name}
                </div>
                <div className="d-flex justify-content-center">
                  <label className="btn btn-outline-primary btn-sm my-3">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => fileHandler(e)}
                    />
                    Choose File
                  </label>
                </div>
                <p className="text-center">Only .xlsx files can be uploaded</p>
                <div className="d-flex justify-content-center my-5">
                  <button
                    type="submit"
                    className="border-0 rounded-3 py-2 text-lg w-full max-w-[200px] transition-all duration-300 text-white bg-gradient-to-r from-[#0089B8] to-[#162B46] hover:from-[#0099cc] hover:to-[#1e3a5e]"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={generateCodesIsOpen}
        style={customStyles}
        onRequestClose={() => setGenerateCodesIsOpen(false)}
        contentLabel="Add Modal"
        ariaHideApp={false}
        className={styles.ModalSmall}
        overlayClassName={styles.Overlay}
      >
        <div className="modal-body">
          <div className="row">
            <form
              onSubmit={(e) => {
                handleGenerateCodes();
                setGenerateCodesIsOpen(false);
              }}
            >
              <div className="col">
                <h3 className="mt-3 text-center font-semibold tracking-tight bg-gradient-to-r from-[#0089B8] to-[#162B46] bg-clip-text text-transparent">
                  Generate Codes
                </h3>
                <h6 className="text-center text-muted">
                  Configure and generate your codes
                </h6>
                <div className="d-flex justify-content-center">
                  <hr className="m-0 w-75 border-0"></hr>
                </div>
                <div className="d-flex justify-content-around py-5">
                  <div className="col-5">
                    <label
                      htmlFor="numberOfCodes"
                      className="form-label font-semibold text-[#162B46]"
                    >
                      Number of Codes
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="numberOfCodes"
                      step={1}
                      min={1}
                      value={numberOfCodes}
                      onChange={(e) => setNumberOfCodes(e.target.value)}
                    />
                  </div>
                  <div className="col-5">
                    <label
                      htmlFor="numberOfLOCs"
                      className="form-label font-semibold text-[#162B46]"
                    >
                      Number of LOCs
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="numberOfLOCs"
                      step={1}
                      min={1}
                      max={2000}
                      value={numberOfLOCs}
                      onChange={(e) => setNumberOfLOCs(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <hr className="m-0 w-75 border-0"></hr>
                </div>
                <div className="d-flex justify-content-center my-3">
                  <button
                    type="submit"
                    className="border-0 rounded-3 py-2 text-xl w-full max-w-xs transition-all duration-300 text-white bg-gradient-to-r from-[#0089B8] to-[#162B46] hover:from-[#0099cc] hover:to-[#1e3a5e]"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default RedeemCodes;
